import { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Link from "@mui/material/Link";

import { BookDetails } from "~/api";

const DESCRIPTION_LIMIT = 300;

const PLACEHOLDERS = (
  <Typography>
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton />
  </Typography>
);

export interface BookInfoDialogDetailsProps {
  open?: boolean;
  loading?: boolean;
  data?: BookDetails | undefined;
}

export function BookInfoDialogDetails(props: BookInfoDialogDetailsProps) {
  const { open, loading, data } = props;
  const { description } = data?.details ?? {};

  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    setShowMore(false);
  }, [open]);

  return (
    <>
      <Typography variant="overline" color="GrayText">
        Description
      </Typography>
      {loading && PLACEHOLDERS}
      {!loading &&
        (description ? (
          description.length <= DESCRIPTION_LIMIT ? (
            <Typography variant="body1">{description}</Typography>
          ) : (
            <Typography variant="body1">
              {showMore ? description : description.slice(0, DESCRIPTION_LIMIT) + "..."}
              <br />
              <Link
                variant="caption"
                onClick={() => setShowMore((more) => !more)}
                sx={{ cursor: "pointer" }}
              >
                Show {showMore ? "less" : "more"}
              </Link>
            </Typography>
          )
        ) : (
          <Typography variant="body2" color="GrayText">
            No description available
          </Typography>
        ))}
    </>
  );
}
