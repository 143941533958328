import { ThemeProvider, CssBaseline, LinearProgress } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { defaultTheme } from "~/config/theme";
import { AppLoadingBar } from "~/components/core/AppLoadingBar";

import { router } from "./routes";

const queryClient = new QueryClient();

export function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <AppLoadingBar />
        <RouterProvider
          router={router}
          fallbackElement={
            <LinearProgress
              sx={{ position: "fixed", top: 0, zIndex: 10000, width: "100%" }}
            />
          }
        />
      </QueryClientProvider>
    </ThemeProvider>
  );
}
