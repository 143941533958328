import Stack from "@mui/material/Stack";

import { Title } from "~/components/core/Title";
import { UnitStudentsSearchField } from "~/components/search/UnitStudentsSearchField";
import { UnitStudentsSearchResults } from "~/components/search/UnitStudentsSearchResults";

export function AdminSearch() {
  return (
    <>
      <Title>
        <b>SEARCH</b> STUDENTS BY UNIT
      </Title>
      <Stack spacing={3}>
        <UnitStudentsSearchField />
        <UnitStudentsSearchResults />
      </Stack>
    </>
  );
}
