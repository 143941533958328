import { useCallback } from "react";
import { Link } from "react-router-dom";
import { atom, useAtom, useSetAtom } from "jotai";

import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import BooksIcon from "@mui/icons-material/MenuBook";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PreviewIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import EventsIcon from "@mui/icons-material/Dvr";

import { useStyles } from "~/utils/styles";
import { useUser } from "~/components/auth/Authentication";

const appDrawerOpen = atom(false);

const adminItems = [
  {
    to: "/",
    title: "DASHBOARD",
    Icon: DashboardIcon,
  },
  {
    to: "/admin/manage",
    title: "MANAGE BOOKLISTS",
    Icon: BooksIcon,
  },
  {
    to: "/admin/preview",
    title: "PREVIEW BOOKLIST",
    Icon: PreviewIcon,
  },
  {
    to: "/admin/search",
    title: "SEARCH FOR STUDENTS",
    Icon: SearchIcon,
  },
  {
    to: "/admin/events",
    title: "AUDIT LOG",
    Icon: EventsIcon,
  },
];

export function useAppDrawer() {
  const setOpen = useSetAtom(appDrawerOpen);
  const openDrawer = useCallback(() => setOpen(true), [setOpen]);
  const closeDrawer = useCallback(() => setOpen(false), [setOpen]);
  const toggleDrawer = useCallback(() => setOpen((open) => !open), [setOpen]);
  return { setOpen, openDrawer, closeDrawer, toggleDrawer };
}

/**
 * Shows a navigation drawer with links based on user role.
 */
export function AppDrawer() {
  const { palette, FontFamily } = useStyles();

  const [user] = useUser();
  const [open, setOpen] = useAtom(appDrawerOpen);

  return (
    <Drawer open={open} onClose={() => setOpen(false)}>
      <Toolbar sx={{ color: "white", backgroundColor: palette.primary.main }}>
        <Typography variant="h6" fontFamily={FontFamily.condensed}>
          <b>MONASH</b> STUDENT BOOKLIST
        </Typography>
      </Toolbar>
      <List>
        {user?.type === "staff" &&
          adminItems.map(({ to, title, Icon }) => (
            <ListItemButton
              key={to}
              to={to}
              component={Link}
              onClick={() => setOpen(false)}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText
                primary={title}
                primaryTypographyProps={{ fontFamily: FontFamily.condensed }}
              />
            </ListItemButton>
          ))}
      </List>
    </Drawer>
  );
}
