import { atom, useAtom } from "jotai";
import { format } from "date-fns";
import { useCallback } from "react";

import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Alert from "@mui/material/Alert";

import { useStyles } from "~/utils/styles";
import { useGetEvents } from "~/data/events";
import { useLoadResource } from "~/components/core/AppLoadingBar";
import {
  ALL_EVENT_TYPES,
  useSelectedEventType,
} from "~/components/events/EventTypeSelect";

const createPlaceholders = (length: number) =>
  Array.from({ length }).map((_, index) => (
    <ListItem key={index}>
      <ListItemText
        primary={
          <Box component="span" display="flex" gap={1}>
            <Skeleton width={400} component="span" />
          </Box>
        }
        secondary={<Skeleton width={200} component="span" />}
      />
    </ListItem>
  ));

const EventListPage = atom(0);
const EventListPageSize = atom(10);

export function useEventListPage() {
  return useAtom(EventListPage);
}

export function useEventListPageSize() {
  return useAtom(EventListPageSize);
}

export function EventList() {
  const { palette } = useStyles();
  const type = useSelectedEventType();

  const [page, setPage] = useEventListPage();
  const [pageSize, setPageSize] = useEventListPageSize();

  const { data, error, isLoading } = useGetEvents({
    type: type === ALL_EVENT_TYPES ? undefined : type,
    page,
    pageSize,
  });

  useLoadResource(useCallback(() => isLoading, [isLoading]));

  const { count = 0, items = [] } = data ?? {};

  const pagination = (
    <TablePagination
      component="div"
      count={isLoading ? 0 : count}
      page={isLoading || count == 0 ? 0 : Math.min(page, 100)}
      onPageChange={(e, page) => setPage(page)}
      rowsPerPage={pageSize}
      rowsPerPageOptions={[10, 25, 50, 100]}
      onRowsPerPageChange={(e) => {
        setPage(0);
        setPageSize(Number(e.target.value));
      }}
      backIconButtonProps={{ disabled: page <= 0 }}
      nextIconButtonProps={{ disabled: page * pageSize > count - pageSize }}
    />
  );

  const empty = (
    <Box p={3}>
      <Typography variant="body2" color="GrayText">
        No events available.
      </Typography>
    </Box>
  );

  return (
    <Box component={Paper}>
      {pagination}
      <List
        disablePadding
        sx={{
          borderTop: "solid",
          borderBottom: "solid",
          borderWidth: 1,
          borderColor: palette.grey[200],
        }}
      >
        {isLoading && createPlaceholders(pageSize)}
        {!isLoading && !error && items.length === 0 && empty}
        {error ? <Alert severity="error">{String(error)}</Alert> : null}
        {items.map(({ id, at, description }) => (
          <ListItem key={id}>
            <ListItemText
              primary={
                <Box component="span" display="flex" gap={1}>
                  <Typography
                    component="span"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                  >
                    {description}
                  </Typography>
                </Box>
              }
              secondary={format(new Date(at), "h:mm a – eeee, do MMMM yyyy")}
            />
          </ListItem>
        ))}
      </List>
      {pagination}
    </Box>
  );
}
