/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PaginatedEvents } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class EventsApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Events API
   * @name GetEvents
   * @summary Get latest events
   * @request GET:/api/events
   * @response `200` `PaginatedEvents`
   */
  getEvents = (
    query?: {
      type?: string;
      pageSize?: number;
      page?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<PaginatedEvents, any>({
      path: `/api/events`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events API
   * @name GetTypes
   * @summary Get a list of event types
   * @request GET:/api/events/types
   * @response `200` `(string)[]`
   */
  getTypes = (params: RequestParams = {}) =>
    this.request<string[], any>({
      path: `/api/events/types`,
      method: "GET",
      format: "json",
      ...params,
    });
}
