import { format } from "date-fns";

import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";

import { useStyles } from "~/utils/styles";
import { BooklistSearchResponse } from "~/api";

export interface PreviewSearchResultsStudentCardProps {
  student: Required<BooklistSearchResponse>["student"];
}

export function PreviewSearchResultsStudentCard(
  props: PreviewSearchResultsStudentCardProps,
) {
  const { student } = props;
  const { palette } = useStyles();
  return (
    <Card>
      <Box p={2}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Avatar sx={{ backgroundColor: palette.primary.main, mr: 2 }}>
              {student.name[0]}
            </Avatar>
            <Stack>
              <Typography variant="body1" fontWeight="bold">
                {student.name}
              </Typography>
              <Typography variant="body2" color="GrayText">
                Last signed in {format(new Date(student.lastSignedIn), "d/MM/yyyy")}
              </Typography>
            </Stack>
          </Box>
          <Hidden mdDown>
            <Stack alignItems="flex-end">
              <Typography variant="body1">{student.studentId}</Typography>
              <Typography variant="body2" color="GrayText">
                {student.email}
              </Typography>
            </Stack>
          </Hidden>
        </Box>
      </Box>
    </Card>
  );
}
