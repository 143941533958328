import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { BuyListsApi, useApi } from "~/api";
import { useLoadResource } from "~/components/core/AppLoadingBar";

export function useGetMyBuyList(disabled?: boolean) {
  const api = useApi(BuyListsApi);
  return useQuery({
    queryKey: ["my", "buylist"],
    queryFn: () => api.getBuyList(),
    enabled: !disabled,
  });
}

export function useInBuyList(bookId?: string, disabled?: boolean) {
  const { isFetching, data } = useGetMyBuyList(disabled);
  const book = data?.find((book) => book.id === bookId);
  return { isFetching, book };
}

export function useUpdateBuyList(resourceKey: string) {
  const api = useApi(BuyListsApi);
  const client = useQueryClient();
  const [load, unload] = useLoadResource(resourceKey);
  return useMutation({
    mutationFn: async (options: { bookId: string; action: "add" | "remove" }) => {
      const { bookId, action } = options;
      load();
      try {
        if (action === "add") await api.addToBuyList(bookId);
        else await api.removeFromBuyList(bookId);
        await client.refetchQueries({ queryKey: ["my", "buylist"] });
      } finally {
        unload();
      }
    },
  });
}
