/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Bookshop, ErrorResponse } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class BookshopsApi<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Bookshops API
   * @name ListBookshops
   * @summary Get all bookshops
   * @request GET:/api/bookshops
   * @response `200` `(Bookshop)[]`
   */
  listBookshops = (params: RequestParams = {}) =>
    this.request<Bookshop[], any>({
      path: `/api/bookshops`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Bookshops API
   * @name GetBookshop
   * @summary Get a bookshop by ID
   * @request GET:/api/bookshops/{bookshopId}
   * @response `200` `Bookshop`
   * @response `404` `ErrorResponse` Bookshop not found
   */
  getBookshop = (bookshopId: string, params: RequestParams = {}) =>
    this.request<Bookshop, ErrorResponse>({
      path: `/api/bookshops/${bookshopId}`,
      method: "GET",
      format: "json",
      ...params,
    });
}
