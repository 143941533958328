import { useQuery } from "@tanstack/react-query";
import { BookshopsApi, useApi } from "~/api";

export function useGetBookshops() {
  const api = useApi(BookshopsApi);
  return useQuery({
    queryKey: ["bookshops"],
    queryFn: () => api.listBookshops({}),
  });
}

export function useGetBookshop(bookshopId: string = "") {
  const api = useApi(BookshopsApi);
  return useQuery({
    queryKey: ["bookshops", bookshopId],
    queryFn: () => api.getBookshop(bookshopId),
    enabled: Boolean(bookshopId),
  });
}
