import { filesize } from "filesize";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";

import { useBooklistUploadFiles, useBooklistUploadStatus } from "./BooklistUpload";

export function BooklistUploadFileList() {
  const [status] = useBooklistUploadStatus();
  const [files, setFiles] = useBooklistUploadFiles();

  if (status === "success") return null;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {files.length > 1 && (
        <Alert severity="info">
          Multiple CSV files will be joined together to form one booklist.
        </Alert>
      )}
      <List dense disablePadding>
        {files?.map((file) => (
          <ListItem
            key={file.name}
            disableGutters
            secondaryAction={
              <IconButton
                size="small"
                onClick={() => setFiles((files) => files.filter((f) => f !== file))}
                disabled={status === "pending"}
              >
                <CloseIcon />
              </IconButton>
            }
          >
            <ListItemText
              primary={file.name}
              secondary={filesize(file.size, { round: 1 }).toString()}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
