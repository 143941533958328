import Fade from "@mui/material/Fade";
import LinearProgress from "@mui/material/LinearProgress";

import { useStyles } from "~/utils/styles";

import { useIsResourceLoading } from "./state";

export function AppLoadingBar() {
  const loading = useIsResourceLoading();
  const { below } = useStyles();
  return (
    <Fade in={loading}>
      <LinearProgress
        sx={{
          position: "fixed",
          top: 64,
          width: "100%",
          [below.sm]: { top: 56 },
        }}
      />
    </Fade>
  );
}
