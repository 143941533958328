import { responsiveFontSizes, createTheme } from "@mui/material";
import { green, red } from "@mui/material/colors";

import "./styles.scss";

export const MonashPalette = {
  background: "#FAFAFA",
  blue: "#006DAE",
  darkGray: "#3C3C3C",
  mediumGray: "#505050",
  lightGray: "#F6F6F6",
  footerText: "#EAEAEA",
  purple: "#746FB2",
  fuschia: "#9651A0",
  ruby: "#C8008F",
  orange: "#D93F00",
  umber: "#795549",
  olive: "#6F7C4D",
  green: "#008A25",
};

const BASE_FONT_FAMILY = ["Arial", "sans-serif"];

export const FontFamily = {
  normal: ["Roboto"].concat(BASE_FONT_FAMILY).join(","),
  condensed: ["Roboto Condensed", "Arial Narrow"].concat(BASE_FONT_FAMILY).join(","),
};

export const defaultTheme = responsiveFontSizes(
  createTheme({
    palette: {
      background: {
        default: MonashPalette.background,
      },
      primary: {
        main: MonashPalette.blue,
      },
      secondary: {
        main: MonashPalette.darkGray,
      },
      error: {
        main: red[700],
      },
      success: {
        main: green[800],
      },
    },
    typography: {
      fontFamily: FontFamily.normal,
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "0.8rem",
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: "hover",
        },
      },
    },
  }),
);
