import { useMemo } from "react";

import Box from "@mui/material/Box";
import MUIAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import MenuIcon from "@mui/icons-material/Menu";
import SignOutIcon from "@mui/icons-material/ExitToApp";

import { useStyles } from "~/utils/styles";
import { AuthApi, useApi } from "~/api";
import { useMenuAnchor } from "~/utils/menu";
import { useUser } from "~/components/auth/Authentication";
import { useAppDrawer } from "~/components/navigation/AppDrawer";
import { useLoadResource } from "~/components/core/AppLoadingBar";

import monashLogo from "~/assets/monash-dark.png";

/**
 * Renders the app navigation bar.
 */
export function AppBar() {
  const { palette, below } = useStyles();

  const auth = useApi(AuthApi);
  const [user] = useUser();
  const [load] = useLoadResource();
  const { toggleDrawer } = useAppDrawer();
  const [anchor, openMenu, closeMenu] = useMenuAnchor();

  const handleSignOut = () => {
    load();
    auth.signOut().finally(() => {
      window.location.href = "/";
    });
  };

  const initial = user?.name?.[0];
  const userType = user?.type;

  const actions = useMemo(() => {
    switch (userType) {
      case "student":
      case "staff":
        return (
          <IconButton onClick={openMenu} edge="end" size="medium" sx={{}}>
            <Avatar sx={{ backgroundColor: palette.primary.main }}>
              <b>{initial}</b>
            </Avatar>
          </IconButton>
        );
      default:
        return null;
    }
  }, [user?.type, openMenu, initial]);

  const menu = useMemo(() => {
    switch (userType) {
      case "staff":
        return (
          <IconButton edge="start" onClick={toggleDrawer} size="large" sx={{ mr: 1.5 }}>
            <MenuIcon />
          </IconButton>
        );
      default:
        return null;
    }
  }, [userType, toggleDrawer]);

  return (
    <>
      <MUIAppBar elevation={1} sx={{ backgroundColor: "white" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box display="flex" alignItems="center" height="100%">
            {menu}
            <Box
              component="img"
              src={monashLogo}
              alt="Monash University Logo"
              sx={{ width: 120, [below.sm]: { width: 100 } }}
            />
          </Box>
          <Box>{actions}</Box>
        </Toolbar>
        <Menu
          open={Boolean(anchor)}
          anchorEl={anchor}
          onClose={closeMenu}
          MenuListProps={{ disablePadding: true }}
        >
          <Box display="flex" alignItems="center" p={2}>
            <Avatar
              sx={{
                backgroundColor: palette.primary.main,
                mr: 2,
              }}
            >
              <b>{initial}</b>
            </Avatar>
            <Box>
              <Typography variant="body1">
                <b>{user?.name}</b>
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {user?.email}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <MenuItem onClick={handleSignOut} sx={{ pt: 1.5, pb: 1.5 }}>
            <ListItemIcon>
              <SignOutIcon />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </MenuItem>
        </Menu>
      </MUIAppBar>
      <Box sx={{ m: 4, [below.sm]: { m: 3 } }} />
    </>
  );
}
