import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import { Title } from "~/components/core/Title";
import { BooklistUpload } from "~/components/booklist/BooklistUpload";

export function AdminUpload() {
  return (
    <Container maxWidth="sm">
      <Title>
        UPLOAD NEW <b>BOOKLIST</b>
      </Title>
      <Box mb={4}>
        <Typography variant="body1" color="GrayText" pb={1}>
          Upload CSV files to create a <b>new booklist</b> for the specified bookshop.
          Only the latest booklist of each bookshop will be visible to students.
        </Typography>
        <Typography variant="body2" color="GrayText">
          Your CSV file should use <b>tab</b> as the delimiter and should <b>not</b>{" "}
          contain a header row.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <BooklistUpload.Options />
        <BooklistUpload.Errors />
        <BooklistUpload.Dropzone />
        <BooklistUpload.FileList />
        <BooklistUpload.Action />
      </Box>
    </Container>
  );
}
