import { atom, useAtom, useAtomValue } from "jotai";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { useGetEventTypes } from "~/data/events";

export const ALL_EVENT_TYPES = "ALL_EVENT_TYPES";

const SelectedEventType = atom(ALL_EVENT_TYPES);

export function useSelectedEventType() {
  return useAtomValue(SelectedEventType);
}

export function EventTypeSelect() {
  const { data, isLoading } = useGetEventTypes();
  const [eventType, setEventType] = useAtom(SelectedEventType);

  return (
    <FormControl fullWidth size="small" disabled={isLoading || data?.length === 0}>
      <InputLabel id="event-type-select-label" shrink>
        Event Type
      </InputLabel>
      <Select
        labelId="event-type-select-label"
        id="event-type-select"
        label="Event Type"
        value={eventType}
        onChange={(e) => setEventType(e.target.value)}
      >
        <MenuItem value={ALL_EVENT_TYPES}>
          <em>All event types</em>
        </MenuItem>
        {data?.map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
