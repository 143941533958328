import { useEffect, useState } from "react";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useIsResourceLoading } from "~/components/core/AppLoadingBar";
import { UnitStudentsSearchResults } from "~/components/search/UnitStudentsSearchResults";
import { useLocationSearchParams } from "~/utils/route";

export function useUnitStudentsSearch() {
  const [params, navigate] = useLocationSearchParams<"unitCode">();
  return [params.get("unitCode") ?? "", navigate] as const;
}

export function UnitStudentsSearchField() {
  const [unitCode, navigate] = useUnitStudentsSearch();
  const loading = useIsResourceLoading(UnitStudentsSearchResults.name);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
  }, [inputValue]);

  useEffect(() => {
    if (!inputValue && unitCode) setInputValue(unitCode);
  }, [unitCode]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const sanitised = inputValue.trim();
        if (!sanitised) {
          setError(`Please enter a valid unit code`);
        } else {
          setError("");
          navigate({ unitCode: inputValue.toUpperCase().trim() });
        }
      }}
    >
      <TextField
        type="text"
        variant="outlined"
        size="small"
        error={Boolean(error)}
        disabled={loading}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        helperText={error}
        label="Enter unit code"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" type="submit" disabled={loading}>
                <ArrowForwardIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
}
