import { atom, useAtom } from "jotai";
import { format } from "date-fns";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { useSelectedBooklist } from "~/components/booklist/BooklistSelect";

const BooklistInfoDialogOpen = atom(false);

export function useBooklistInfoDialog() {
  return useAtom(BooklistInfoDialogOpen);
}

export function BooklistInfoDialog() {
  const [open, setOpen] = useBooklistInfoDialog();
  const booklist = useSelectedBooklist();

  const { id, bookshopId, created = new Date(), year } = booklist ?? {};
  const rows = [
    { name: "ID", value: id },
    { name: "Bookshop", value: bookshopId },
    { name: "Year", value: year },
    {
      name: "Created",
      value: format(new Date(created), "EEEE, dd MMMM yyyy, h:mm a"),
    },
  ];

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth>
      <DialogTitle>Booklist details</DialogTitle>
      <DialogContent>
        <Stack gap={1}>
          {rows.map(({ name, value }) => (
            <Box key={name}>
              <Typography variant="overline" color="GrayText" lineHeight={1}>
                {name}
              </Typography>
              <Typography variant="body1">{value}</Typography>
            </Box>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={() => setOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
