import { Link as RouterLink } from "react-router-dom";
import { atom, useAtom } from "jotai";

import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Skeleton from "@mui/material/Skeleton";

import { useStyles } from "~/utils/styles";
import { useGetBooklistBooks } from "~/data/booklists";
import { useSelectedBooklist } from "~/components/booklist/BooklistSelect";
import { useIsBooklistDeleting } from "~/components/booklist/BooklistDeleteDialog";
import { useSelectedBooklistUnitCodeFilter } from "~/components/booklist/BooklistUnitCodeFilter";
import { useBookInfoDialog } from "~/components/book/BookInfoDialog";
import { useLoadResource } from "~/components/core/AppLoadingBar";
import { useCallback } from "react";

const createPlaceholders = (length: number) =>
  Array.from({ length }).map((_, index) => (
    <ListItem key={index}>
      <ListItemText
        primary={
          <Box component="span" display="flex" gap={1}>
            <Skeleton width={100} component="span" />
            <Skeleton width={400} component="span" />
          </Box>
        }
        secondary={<Skeleton width={150} component="span" />}
      />
    </ListItem>
  ));

const BooklistListPage = atom(0);
const BooklistListPageSize = atom(10);

export function useBooklistListPage() {
  return useAtom(BooklistListPage);
}

export function useBooklistListPageSize() {
  return useAtom(BooklistListPageSize);
}

export function BooklistList() {
  const { palette } = useStyles();

  const booklist = useSelectedBooklist();
  const deleting = useIsBooklistDeleting();

  const [unitCodeFilter] = useSelectedBooklistUnitCodeFilter();
  const { openBookDialog } = useBookInfoDialog();

  const [page, setPage] = useBooklistListPage();
  const [pageSize, setPageSize] = useBooklistListPageSize();

  const { data, isLoading: booksLoading } = useGetBooklistBooks(booklist?.id, {
    unitCode: unitCodeFilter,
    page,
    pageSize,
  });

  useLoadResource(
    useCallback(() => booksLoading && !!booklist?.id, [booksLoading, booklist?.id]),
  );

  const { count = 0, items = [] } = data ?? {};

  const pagination = (
    <TablePagination
      component="div"
      count={booksLoading ? 0 : count}
      page={booksLoading || count == 0 ? 0 : Math.min(page, 100)}
      onPageChange={(e, page) => setPage(page)}
      rowsPerPage={pageSize}
      rowsPerPageOptions={[10, 25, 50, 100]}
      onRowsPerPageChange={(e) => {
        setPage(0);
        setPageSize(Number(e.target.value));
      }}
      SelectProps={{ disabled: deleting }}
      backIconButtonProps={{ disabled: deleting || page <= 0 }}
      nextIconButtonProps={{ disabled: deleting || page * pageSize >= count - pageSize }}
    />
  );

  const empty = (
    <Box p={3}>
      <Typography variant="body2" color="GrayText">
        No booklists available.{" "}
        <Link component={RouterLink} to="/admin/manage/upload">
          Upload a booklist
        </Link>{" "}
        to get started.
      </Typography>
    </Box>
  );

  const loading = booklist && booksLoading;

  return (
    <Box component={Paper}>
      {pagination}
      <List
        disablePadding
        sx={{
          borderTop: "solid",
          borderBottom: "solid",
          borderWidth: 1,
          borderColor: palette.grey[200],
        }}
      >
        {loading && createPlaceholders(pageSize)}
        {!loading && items.length === 0 && empty}
        {items.map((book) => {
          const { id, unitCode, teachingPeriod, title, author } = book;
          return (
            <ListItem key={id} disablePadding>
              <ListItemButton onClick={() => openBookDialog(book)}>
                <ListItemText
                  primary={
                    <Box component="span" display="flex" gap={1}>
                      <Typography component="span" fontWeight="bold" whiteSpace="nowrap">
                        {unitCode} – {teachingPeriod}
                      </Typography>
                      <Typography
                        component="span"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                      >
                        {title}
                      </Typography>
                    </Box>
                  }
                  secondary={<span>{author ?? <em>No author listed</em>}</span>}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      {pagination}
    </Box>
  );
}
