/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Booklist,
  BooklistCreated,
  BooklistFilter,
  BooklistUpload,
  BooklistUploadError,
  PaginatedBooklistBooks,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class BooklistsApi<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * @description Booklists ordered by creation date with newest booklists first.
   *
   * @tags Booklists API
   * @name ListBooklists
   * @summary Get all booklists
   * @request GET:/api/booklists
   * @response `200` `(Booklist)[]`
   */
  listBooklists = (
    query?: {
      /** Filter by a specific bookshop */
      bookshopId?: string;
      /** Filter by a specific year */
      year?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Booklist[], any>({
      path: `/api/booklists`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * @description Create a new booklist by uploading CSV files.
   *
   * @tags Booklists API
   * @name UploadBooklist
   * @summary Upload a booklist
   * @request POST:/api/booklists
   * @response `200` `BooklistCreated`
   * @response `400` `BooklistUploadError` Invalid booklist
   */
  uploadBooklist = (data: BooklistUpload, params: RequestParams = {}) =>
    this.request<BooklistCreated, BooklistUploadError>({
      path: `/api/booklists`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Booklists API
   * @name DeleteBooklist
   * @summary Delete a booklist
   * @request DELETE:/api/booklists/{booklistId}
   * @response `200` `void`
   */
  deleteBooklist = (booklistId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/booklists/${booklistId}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags Booklists API
   * @name GetBooklistBooks
   * @summary Get books from a booklist
   * @request GET:/api/booklists/{booklistId}/books
   * @response `200` `PaginatedBooklistBooks`
   */
  getBooklistBooks = (
    booklistId: string,
    query?: {
      pageSize?: number;
      page?: number;
      /** Filter by unit code */
      unitCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<PaginatedBooklistBooks, any>({
      path: `/api/booklists/${booklistId}/books`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Booklists API
   * @name GetBooklistFilters
   * @summary Get filters for a booklist
   * @request GET:/api/booklists/{booklistId}/filters
   * @response `200` `(BooklistFilter)[]`
   */
  getBooklistFilters = (
    booklistId: string,
    query: {
      /** The filter type to get */
      type: "unitCode" | "teachingPeriod";
      /** Search for a filter */
      search?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<BooklistFilter[], any>({
      path: `/api/booklists/${booklistId}/filters`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}
