import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { useInBuyList, useUpdateBuyList } from "~/data/buy-list";

export interface BookInfoDialogAddToBuyProps {
  bookId?: string;
}

export function BookInfoDialogBuyList(props: BookInfoDialogAddToBuyProps) {
  const { bookId } = props;

  const { isFetching, book: buyListBook } = useInBuyList(bookId);
  const updateBuyList = useUpdateBuyList(BookInfoDialogBuyList.name);

  return (
    <Button
      variant={buyListBook ? "outlined" : "contained"}
      disabled={isFetching || updateBuyList.isPending}
      fullWidth
      onClick={() =>
        bookId && updateBuyList.mutate({ bookId, action: buyListBook ? "remove" : "add" })
      }
    >
      <Typography mr={2}>
        {buyListBook ? "Remove from buy list" : "Add to buy list"}
      </Typography>
    </Button>
  );
}
