import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import { useBooklistUploadError } from "./BooklistUpload";

export function BooklistUploadErrors() {
  const [error] = useBooklistUploadError();

  if (!error) return null;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Alert severity="error">
        <AlertTitle>
          <b>{error.message}</b>
        </AlertTitle>
        <Box component="ul" pl={3} m={0}>
          {error.issues.map((issue) => {
            const { id, message, data = {} } = issue;

            const location: string[] = [];
            if (typeof data.filename !== "undefined") location.push(`${data.filename}`);
            if (typeof data.row !== "undefined") location.push(`row ${data.row}`);
            if (typeof data.path !== "undefined")
              location.push(`${JSON.stringify(data.path)}`);
            if (location.length)
              return (
                <Typography key={id} variant="body2" component="li">
                  <b>{location.join(", ")}</b>
                  <br />
                  {message}
                </Typography>
              );

            const book: string[] = [];
            if (typeof data.unitCode !== "undefined") book.push(data.unitCode);
            if (typeof data.teachingPeriod !== "undefined")
              book.push(data.teachingPeriod);
            if (book.length)
              return (
                <Typography key={id} variant="body2" component="li">
                  <b>{book.join(" - ")}</b>
                  <br />
                  {message}
                </Typography>
              );

            return (
              <Typography key={id} variant="body2" component="li">
                {message}
              </Typography>
            );
          })}
        </Box>
      </Alert>
    </Box>
  );
}
