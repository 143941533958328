import { useQuery } from "@tanstack/react-query";

import { EventsApi, useApi } from "~/api";

export function useGetEventTypes() {
  const api = useApi(EventsApi);
  return useQuery({
    queryKey: ["events", "types"],
    queryFn: () => api.getTypes(),
  });
}

export function useGetEvents(
  options: {
    type?: string;
    page?: number;
    pageSize?: number;
  } = {},
) {
  const { type, page, pageSize } = options;
  const api = useApi(EventsApi);
  return useQuery({
    queryKey: ["events", { page, pageSize, type }],
    queryFn: () => api.getEvents({ page, pageSize, type }),
    retry: false,
  });
}
