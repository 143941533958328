import { AxiosError } from "axios";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect } from "react";

import { AuthApi, UserInfo, useApi } from "~/api";

import { useLoadResource } from "~/components/core/AppLoadingBar";

const userState = atom<UserInfo | null>(null);
const userLoadingState = atom(true);

export function useUser() {
  return [useAtomValue(userState), useAtomValue(userLoadingState)] as const;
}

export function Authentication() {
  const auth = useApi(AuthApi);

  const [load, unload] = useLoadResource();
  const setLoading = useSetAtom(userLoadingState);
  const setUser = useSetAtom(userState);

  const getUser = useCallback(async () => {
    setLoading(true);
    load();
    try {
      const user = await auth.getUser();
      setUser(user);
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 401) return;
      throw error;
    } finally {
      setLoading(false);
      unload();
    }
  }, []);

  useEffect(() => {
    getUser();
  }, []);

  return null;
}
