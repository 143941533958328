import { useDropzone } from "react-dropzone";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import LoadingIcon from "@mui/icons-material/Upload";

import { useStyles } from "~/utils/styles";
import { useIsResourceLoading } from "~/components/core/AppLoadingBar";

import {
  BooklistUpload,
  useBooklistUploadFiles,
  useBooklistUploadStatus,
} from "./BooklistUpload";

export function BooklistUploadDropzone() {
  const { transitions, palette, lighten } = useStyles();

  const [, setFiles] = useBooklistUploadFiles();
  const [status] = useBooklistUploadStatus();

  const disabled = useIsResourceLoading(BooklistUpload.name);
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    multiple: true,
    disabled,
    accept: {
      "text/csv": [".csv"],
      "application/vnd.ms-excel": [".csv"],
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      setFiles((files) => files.concat(acceptedFiles as File[]));
      if (rejectedFiles.length) console.warn("Rejected files", rejectedFiles);
    },
  });

  if (status === "pending")
    return (
      <Alert severity="info" icon={<LoadingIcon />}>
        Uploading booklist. Do not navigate away from this page.
      </Alert>
    );

  if (status === "success") return null;

  const transition = transitions.create(["border-color", "background-color"], {
    duration: "200ms",
  });

  return (
    <Box
      {...getRootProps()}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight={150}
      p={1}
      sx={{
        transition,
        borderStyle: "dashed",
        borderWidth: "2px",
        borderRadius: 2,
        cursor: "pointer",
        ...(disabled && {
          opacity: 0.5,
          borderColor: palette.grey[400],
        }),
        ...(!disabled &&
          !isDragActive && {
            borderColor: lighten(palette.primary.light, 0.5),
            "&:hover": {
              borderColor: palette.primary.light,
              backgroundColor: lighten(palette.primary.light, 0.95),
            },
            "&:active": {
              backgroundColor: lighten(palette.primary.light, 0.87),
            },
          }),
        ...(!disabled &&
          isDragActive &&
          !isDragReject && {
            borderColor: palette.primary.light,
            backgroundColor: lighten(palette.primary.light, 0.9),
          }),
        ...(!disabled &&
          isDragReject && {
            borderColor: palette.error.light,
            backgroundColor: lighten(palette.error.light, 0.9),
          }),
      }}
    >
      <input {...getInputProps()} />
      <Typography variant="body2" color="GrayText">
        Drag CSV files here or click to select files.
      </Typography>
    </Box>
  );
}
