import { atom, useAtom } from "jotai";

import { BooklistUploadError as BooklistUploadErrorResponse } from "~/api";

import { BooklistUploadOptions } from "./BooklistUploadOptions";
import { BooklistUploadDropzone } from "./BooklistUploadDropzone";
import { BooklistUploadFileList } from "./BooklistUploadFileList";
import { BooklistUploadErrors } from "./BooklistUploadErrors";
import { BooklistUploadAction } from "./BooklistUploadAction";

const BooklistUploadYear = atom(new Date().getFullYear().toString());

export function useBooklistUploadYear() {
  return useAtom(BooklistUploadYear);
}

const BooklistUploadFiles = atom<File[]>([]);

export function useBooklistUploadFiles() {
  return useAtom(BooklistUploadFiles);
}

const BooklistUploadError = atom<BooklistUploadErrorResponse | null>(null);

export function useBooklistUploadError() {
  return useAtom(BooklistUploadError);
}

const BooklistUploadStatus = atom<"pending" | "idle" | "error" | "success">("idle");

export function useBooklistUploadStatus() {
  return useAtom(BooklistUploadStatus);
}

export function BooklistUpload(): null {
  throw new Error(
    `Not a component, use one of: ${Object.entries(
      Object.getOwnPropertyDescriptors(BooklistUpload),
    )
      .filter(([, property]) => property.enumerable)
      .map(([key]) => `${BooklistUpload.name}.${key}`)
      .join(", ")}`,
  );
}

BooklistUpload.Options = BooklistUploadOptions;
BooklistUpload.Dropzone = BooklistUploadDropzone;
BooklistUpload.FileList = BooklistUploadFileList;
BooklistUpload.Errors = BooklistUploadErrors;
BooklistUpload.Action = BooklistUploadAction;
