import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

import { useStyles } from "~/utils/styles";
import { Title } from "~/components/core/Title";
import { BookshopSelect } from "~/components/booklist/BookshopSelect";
import { BooklistSelect } from "~/components/booklist/BooklistSelect";
import { BooklistOptions } from "~/components/booklist/BooklistOptions";
import { BooklistUnitCodeFilter } from "~/components/booklist/BooklistUnitCodeFilter";
import { BooklistInfoDialog } from "~/components/booklist/BooklistInfoDialog";
import { BooklistsDeleteDialog } from "~/components/booklist/BooklistDeleteDialog";
import { BooklistUnitCodeFilterDialog } from "~/components/booklist/BooklistUnitCodeFilterDialog";
import { BooklistList } from "~/components/booklist/BooklistList";
import { BookInfoDialog } from "~/components/book/BookInfoDialog";

export function AdminManage() {
  const { below } = useStyles();
  const action = (
    <Button
      variant="contained"
      startIcon={<AddIcon />}
      component={Link}
      to="/admin/manage/upload"
      sx={{ [below.sm]: { width: "100%" } }}
    >
      New booklist
    </Button>
  );
  return (
    <>
      <Title action={action}>
        MANAGE <b>BOOKLIST</b>
      </Title>
      <Grid container spacing={2} mt={4} alignItems="center">
        <Grid item md={6} sm={7} xs={12}>
          <BookshopSelect />
        </Grid>
        <Grid item md={3} sm={3} xs={7}>
          <BooklistSelect />
        </Grid>
        <Grid item md={3} sm={2} xs={5} display="flex" justifyContent="flex-end">
          <BooklistUnitCodeFilter />
          <BooklistOptions />
        </Grid>
      </Grid>
      <BooklistInfoDialog />
      <BooklistsDeleteDialog />
      <BooklistUnitCodeFilterDialog />
      <Box mt={2}>
        <BooklistList />
      </Box>
      <BookInfoDialog />
    </>
  );
}
