import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { BooklistsApi, BooklistUpload, useApi } from "~/api";
import { useSelectedBookshop } from "~/components/booklist/BookshopSelect";
import { useLoadResource } from "~/components/core/AppLoadingBar";

export function useGetBooklists(bookshopId: string = "") {
  const api = useApi(BooklistsApi);
  return useQuery({
    queryKey: ["booklists", bookshopId],
    queryFn: () => api.listBooklists({ bookshopId }),
    enabled: Boolean(bookshopId),
  });
}

export function useDeleteBooklist(resourceKey: string) {
  const api = useApi(BooklistsApi);
  const client = useQueryClient();
  const bookshop = useSelectedBookshop();
  const [load, unload] = useLoadResource(resourceKey);
  return useMutation({
    mutationFn: async (booklistId: string) => {
      if (!bookshop) return;
      load();
      try {
        await api.deleteBooklist(booklistId);
        client.removeQueries({ queryKey: ["booklists", bookshop.id] });
      } finally {
        unload();
      }
    },
  });
}

export function useGetBooklistBooks(
  booklistId: string = "",
  options: {
    unitCode?: string;
    page?: number;
    pageSize?: number;
  } = {},
) {
  const { unitCode, page, pageSize } = options;
  const api = useApi(BooklistsApi);

  const results = useQuery({
    queryKey: ["booklists", booklistId, "books", { page, pageSize, unitCode }],
    queryFn: () =>
      api.getBooklistBooks(booklistId, {
        page,
        pageSize,
        unitCode: unitCode || undefined,
      }),
    placeholderData: (previousData) => (booklistId ? previousData : undefined),
    enabled: Boolean(booklistId),
  });

  return results;
}

export function useGetBooklistUnitCodeFilters(
  booklistId: string = "",
  search: string = "",
) {
  const api = useApi(BooklistsApi);
  return useQuery({
    queryKey: ["booklists", booklistId, "filters", "unitCode", search],
    queryFn: () => api.getBooklistFilters(booklistId, { type: "unitCode", search }),
    enabled: Boolean(booklistId),
  });
}

export function useUploadBooklist(resourceKey: string) {
  const api = useApi(BooklistsApi);
  const client = useQueryClient();
  const bookshop = useSelectedBookshop();
  const [load, unload] = useLoadResource(resourceKey);
  return useMutation({
    mutationFn: async (options: BooklistUpload) => {
      if (!bookshop) return;
      load();
      try {
        const response = await api.uploadBooklist(options);
        client.removeQueries({ queryKey: ["booklists", options.bookshopId] });
        return response;
      } finally {
        unload();
      }
    },
  });
}
