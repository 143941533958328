import { Container, Constructable } from "typedi";

import { AuthApi } from "./__generated__/AuthApi";
import { BooklistsApi } from "./__generated__/BooklistsApi";
import { BooksApi } from "./__generated__/BooksApi";
import { BookshopsApi } from "./__generated__/BookshopsApi";
import { EnrolmentsApi } from "./__generated__/EnrolmentsApi";
import { EventsApi } from "./__generated__/EventsApi";
import { SearchApi } from "./__generated__/SearchApi";
import { BuyListsApi } from "./__generated__/BuyListsApi";

/**
 * Get an instance of an API class.
 *
 * @param api The API class to get.
 */
export function useApi<
  T extends
    | AuthApi
    | BooklistsApi
    | BooksApi
    | BookshopsApi
    | EnrolmentsApi
    | EventsApi
    | SearchApi
    | BuyListsApi,
>(api: Constructable<T>) {
  return Container.get<T>(api);
}

// Register API classes (need to do this manually since codegen can't add @Service() decorators)
Container.set({ id: AuthApi, type: AuthApi });
Container.set({ id: BooklistsApi, type: BooklistsApi });
Container.set({ id: BooksApi, type: BooksApi });
Container.set({ id: BookshopsApi, type: BookshopsApi });
Container.set({ id: EnrolmentsApi, type: EnrolmentsApi });
Container.set({ id: EventsApi, type: EventsApi });
Container.set({ id: SearchApi, type: SearchApi });
Container.set({ id: BuyListsApi, type: BuyListsApi });

// Export types
export * from "./__generated__/data-contracts";

// Export API classes
export {
  AuthApi,
  BooklistsApi,
  BooksApi,
  BookshopsApi,
  EnrolmentsApi,
  EventsApi,
  SearchApi,
  BuyListsApi,
};

// Custom types
import { PaginatedBooklistBooks } from "./__generated__/data-contracts";
export type BooklistBook = PaginatedBooklistBooks["items"][number];
