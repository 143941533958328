import { useCallback, useMemo } from "react";
import { EError } from "exceptional-errors";
import { NavigateOptions, useLocation, useNavigate } from "react-router-dom";

export class RouteGuardError extends EError<{ status: number; statusText: string }> {}

export function useLocationSearchParams<T extends string = string>() {
  const { search } = useLocation();
  const navigate = useNavigate();
  return [
    useMemo(() => new URLSearchParams(search), [search]),
    useCallback(
      (params: Partial<Record<T, string>>, options?: NavigateOptions) => {
        const newParams = new URLSearchParams(search);
        Object.entries(params).forEach(([key, value]) => {
          newParams.set(key, value as string);
        });
        navigate({ search: newParams.toString() }, options);
      },
      [navigate, search],
    ),
  ] as const;
}
