/**
 * Return the singular or plural form of a word based on the given count,
 * with the count prepended by default.
 *
 * ```
 * pluralise(0, "apple") // 0 apples
 * pluralise(1, "apple") // 1 apple
 * pluralise(2, "apple") // 2 apples
 * pluralise(4, "person", { plural: "people" }) // 4 people
 * pluralise(4, "person", { hideCount: true }) // people
 * ```
 *
 * @param count The count.
 * @param word The singular form of the word.
 * @param options.plural The plural form of the word. By defaults, it appends an "s" to `word`.
 * @param options.hideCount If `true`, the value of `count` will not be included in the output.
 */
export function pluralise(
  count: number,
  word: string,
  options: { plural?: string; hideCount?: boolean } = {},
) {
  const { plural = `${word}s`, hideCount = false } = options;
  const output = count === 1 ? word : plural;
  if (hideCount) return output;
  return `${count} ${output}`;
}

/**
 * Returns the phrase with the first letter capitalised.
 */
export function capitalise(phrase: string) {
  return (phrase[0] ?? "").toUpperCase() + phrase.slice(1);
}

/**
 * Returns the phrase with title case applied to each word.
 */
export function titleCase(phrase: string) {
  const words = phrase.split(" ").filter((x) => x);
  return words
    .map((word) => (word[0] ?? "").toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}
