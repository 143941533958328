import { useMemo } from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { useBooklistUploadYear } from "./BooklistUpload";

export interface BooklistUploadYearSelectProps {
  disabled?: boolean;
}

export function BooklistUploadYearSelect(props: BooklistUploadYearSelectProps) {
  const { disabled } = props;

  const [year, setYear] = useBooklistUploadYear();

  const options = useMemo(() => {
    const thisYear = new Date().getFullYear();
    return [thisYear - 1, thisYear, thisYear + 1, thisYear + 2];
  }, []);

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="booklist-upload-year-select-label">Year</InputLabel>
      <Select
        labelId="booklist-upload-year-select-label"
        id="booklist-upload-year-select"
        label="Booklist"
        value={year}
        disabled={disabled}
        onChange={(e) => {
          setYear(e.target.value);
        }}
      >
        {options.map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
