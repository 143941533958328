import { useEffect } from "react";
import { Link } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { useSearchUnitStudents } from "~/data/search";
import { useLoadResource } from "~/components/core/AppLoadingBar";
import { useUnitStudentsSearch } from "~/components/search/UnitStudentsSearchField";
import { SearchErrorAlert } from "~/components/search/SearchErrorAlert";
import { SearchInProgressLabel } from "~/components/search/SearchInProgressLabel";

export function UnitStudentsSearchResults() {
  const [unitCode] = useUnitStudentsSearch();

  const { isFetching, error, data } = useSearchUnitStudents({ unitCode });
  const [load, unload] = useLoadResource(UnitStudentsSearchResults.name);

  useEffect(() => {
    if (isFetching) load();
    else unload();
  }, [isFetching, unitCode]);

  if (isFetching) return <SearchInProgressLabel />;

  if (error) return <SearchErrorAlert error={error} />;

  if (!data)
    return (
      <Typography variant="body2" color="GrayText">
        Provide a valid unit code, then press enter to search for students who are
        enrolled in the specified unit who have recently signed in.
      </Typography>
    );

  return (
    <Stack gap={2}>
      <Alert severity="info">
        This search only returns a sample of students who have recently signed in. Click
        on a student to view their booklist.
      </Alert>
      {data?.length === 0 && (
        <Typography variant="body2" color="GrayText">
          No students found for {unitCode}.
        </Typography>
      )}
      {data?.length !== 0 && (
        <List>
          {data.map((user) => (
            <ListItem key={user.id} disablePadding>
              <ListItemButton
                component={Link}
                to={`/admin/preview?type=studentId&value=${user.id}`}
              >
                <ListItemText
                  primary={
                    <>
                      <b>{user.name}</b> – {user.id}
                    </>
                  }
                  secondary={user.email}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </Stack>
  );
}
