import { Outlet, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import Box from "@mui/system/Box";
import Container from "@mui/material/Container";

import { AppBar } from "~/components/navigation/AppBar";
import { AppDrawer } from "~/components/navigation/AppDrawer";
import { Authentication, useUser } from "~/components/auth/Authentication";
import { SignInForm } from "~/components/auth/SignInForm";
import { Footer } from "~/components/core/Footer";

export interface RootProps {
  children?: React.ReactNode;
}

export function Root(props: RootProps) {
  const { children = <Outlet /> } = props;

  const navigate = useNavigate();

  const { pathname } = useLocation();
  const [user, loading] = useUser();

  useEffect(() => {
    if (!user) return;
    if (pathname !== "/") return;
    if (user.type === "student") navigate("/books", { replace: true });
    if (user.type === "staff") navigate("/admin", { replace: true });
  }, [user, pathname]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Authentication />
      <AppDrawer />
      <AppBar />
      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        {!loading && !user ? <SignInForm /> : children}
      </Container>
      <Footer />
    </Box>
  );
}
