import { Outlet } from "react-router-dom";

import { UserInfo } from "~/api";
import { RouteGuardError } from "~/utils/route";

import { useUser } from "~/components/auth/Authentication";

export interface GuardProps {
  type?: UserInfo["type"];
}

export function Guard(props: GuardProps) {
  const { type } = props;
  const [user, loading] = useUser();
  if (loading) return null;
  if (type === user?.type) return <Outlet />;
  throw new RouteGuardError("You do not have permission to view this page.", {
    info: { status: 403, statusText: "Forbidden" },
  });
}
