import { atom, useAtom, useAtomValue } from "jotai";

import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import FilterIcon from "@mui/icons-material/FilterAlt";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";

import { useSelectedBooklist } from "~/components/booklist/BooklistSelect";
import { useIsBooklistDeleting } from "~/components/booklist/BooklistDeleteDialog";
import { useBooklistUnitCodeFilterDialog } from "~/components/booklist/BooklistUnitCodeFilterDialog";
import { useGetBooklistUnitCodeFilters } from "~/data/booklists";

const SelectedBooklistUnitCodeFilter = atom("");

export function useSelectedBooklistUnitCodeFilter() {
  return useAtom(SelectedBooklistUnitCodeFilter);
}

export function BooklistUnitCodeFilter() {
  const booklist = useSelectedBooklist();
  const deleting = useIsBooklistDeleting();
  const { data } = useGetBooklistUnitCodeFilters(booklist?.id);
  const disabled = !booklist || !data?.length || deleting;

  const [, setOpen] = useBooklistUnitCodeFilterDialog();
  const filter = useAtomValue(SelectedBooklistUnitCodeFilter);

  const icon = (
    <Badge color="primary" variant="dot" invisible={!Boolean(filter)}>
      <FilterIcon />
    </Badge>
  );

  return (
    <>
      <Hidden mdDown>
        <Button
          endIcon={icon}
          size="large"
          color="inherit"
          sx={{
            color: "GrayText",
            textTransform: "none",
            alignSelf: "center",
          }}
          disabled={disabled}
          onClick={() => setOpen(true)}
        >
          Filter by unit
        </Button>
      </Hidden>
      <Hidden mdUp>
        <IconButton
          size="large"
          sx={{ alignSelf: "center" }}
          disabled={disabled}
          onClick={() => setOpen(true)}
        >
          {icon}
        </IconButton>
      </Hidden>
    </>
  );
}
