import { useEffect } from "react";
import { AxiosError } from "axios";
import { Link as RouterLink } from "react-router-dom";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

import { useStyles } from "~/utils/styles";
import { useUploadBooklist } from "~/data/booklists";
import { useSelectedBookshop } from "~/components/booklist/BookshopSelect";

import {
  BooklistUpload,
  useBooklistUploadError,
  useBooklistUploadFiles,
  useBooklistUploadStatus,
  useBooklistUploadYear,
} from "./BooklistUpload";

export function BooklistUploadAction() {
  const { below } = useStyles();

  const bookshop = useSelectedBookshop();
  const uploadBooklist = useUploadBooklist(BooklistUpload.name);
  const { status, reset } = uploadBooklist;

  const [year] = useBooklistUploadYear();
  const [files, setFiles] = useBooklistUploadFiles();
  const [, setStatus] = useBooklistUploadStatus();
  const [, setError] = useBooklistUploadError();

  useEffect(() => {
    if (!uploadBooklist.error) return;
    if (uploadBooklist.error instanceof AxiosError)
      setError(uploadBooklist.error.response?.data);
  }, [uploadBooklist.error, setError]);

  // Reset error when files change
  useEffect(() => {
    setError(null);
  }, [files]);

  // Update the upload status
  useEffect(() => {
    setStatus(status);
    if (status === "success") setFiles([]);
  }, [status]);

  if (status === "success") {
    return (
      <>
        <Alert>
          Booklist uploaded successfully. Take a look at the{" "}
          <Link component={RouterLink} to="/admin/manage">
            new booklist.
          </Link>
        </Alert>
        <Button
          sx={{ mt: 4 }}
          variant="outlined"
          onClick={() => {
            setFiles([]);
            reset();
          }}
        >
          Upload new booklist
        </Button>
      </>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      flexWrap="wrap"
      gap={1}
      sx={{ [below.sm]: { flexDirection: "column-reverse" } }}
    >
      <Button
        color="inherit"
        disabled={files.length === 0 || status === "pending"}
        onClick={() => setFiles([])}
        sx={{ [below.sm]: { width: "100%" } }}
      >
        Clear
      </Button>
      <Button
        variant="contained"
        disabled={files.length === 0 || status === "pending"}
        sx={{ [below.sm]: { width: "100%" } }}
        onClick={() => {
          if (!bookshop) return;
          setError(null);
          uploadBooklist.mutate({ year, bookshopId: bookshop.id, files });
        }}
      >
        Upload
      </Button>
    </Box>
  );
}
