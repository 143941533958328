import { useNavigate, useRouteError } from "react-router-dom";
import { z } from "zod";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import BackIcon from "@mui/icons-material/ChevronLeft";

import { useStyles } from "~/utils/styles";
import { Root } from "~/pages/root";
import { RouteGuardError } from "~/utils/route";

const RouteError = z.object({
  data: z.string(),
  status: z.number(),
  statusText: z.string(),
});

export function RootErrorBoundary() {
  const { palette } = useStyles();
  const error = useRouteError();
  const navigate = useNavigate();

  let code = "";
  let title = "Application Error";
  let message = "An unknown error occurred.";

  if (error instanceof RouteGuardError && error.info) {
    const { status, statusText } = error.info;
    code = String(status);
    title = statusText;
    message = error.message;
  } else {
    const parsedError = RouteError.safeParse(error);
    if (parsedError.success) {
      const { status, statusText, data } = parsedError.data;
      code = String(status);
      title = statusText;
      if (status === 404) message = "This page does not exist.";
      else message = data;
    }
  }

  return (
    <Root>
      <Typography variant="h4" color="primary">
        {code} <b>{title}</b>
      </Typography>
      <Typography variant="body1" sx={{ mt: 1, mb: 4 }}>
        {message}
      </Typography>
      <Button variant="outlined" onClick={() => navigate(-1)}>
        <BackIcon sx={{ color: palette.primary.main, mr: 1 }} />
        <b>Go back</b>
      </Button>
    </Root>
  );
}
