import { useEffect } from "react";
import { atom, useAtom, useAtomValue } from "jotai";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { useGetBooklists } from "~/data/booklists";
import { useSelectedBookshop } from "~/components/booklist/BookshopSelect";
import { useIsBooklistDeleting } from "~/components/booklist/BooklistDeleteDialog";
import { useBooklistListPage } from "~/components/booklist/BooklistList";

const SelectedBooklistId = atom("");

export function useSelectedBooklist() {
  const bookshop = useSelectedBookshop();
  const { data } = useGetBooklists(bookshop?.id);
  const booklistId = useAtomValue(SelectedBooklistId);
  return data?.find((booklist) => booklist.id === booklistId) ?? null;
}

export function BooklistSelect() {
  const bookshop = useSelectedBookshop();
  const deleting = useIsBooklistDeleting();

  const [booklistId, setBooklistId] = useAtom(SelectedBooklistId);
  const { data, isLoading } = useGetBooklists(bookshop?.id);

  const [, setPage] = useBooklistListPage();

  useEffect(() => {
    if (!bookshop || !data) setBooklistId("");
    else if (!booklistId || !data.find((booklist) => booklist.id === booklistId))
      setBooklistId(data[0]?.id ?? "");
  }, [bookshop, data, booklistId]);

  return (
    <FormControl
      fullWidth
      size="small"
      disabled={isLoading || deleting || !bookshop || data?.length === 0}
    >
      <InputLabel id="booklist-select-label">Booklist</InputLabel>
      <Select
        labelId="booklist-select-label"
        id="booklist-select"
        label="Booklist"
        value={booklistId}
        onChange={(e) => {
          setBooklistId(e.target.value);
          setPage(0);
        }}
      >
        {data?.map(({ id, bookshopId }) => (
          <MenuItem key={id} value={id}>
            {id.replace(`-${bookshopId}`, "")}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
