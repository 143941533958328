import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import LibraryIcon from "@mui/icons-material/LocalLibrary";

import { BookAvailabilities } from "~/api";

export interface BookInfoDialogDetailsProps {
  loading?: boolean;
  data?: BookAvailabilities | undefined;
}

const PLACEHOLDERS = (
  <Typography>
    <Skeleton width="50%" />
    <Skeleton width="50%" />
  </Typography>
);

export function BookInfoDialogAvailabilities(props: BookInfoDialogDetailsProps) {
  const { loading, data } = props;
  const { availabilities } = data ?? {};

  return (
    <>
      <Typography variant="overline" color="GrayText">
        Library availability
      </Typography>
      {loading && PLACEHOLDERS}
      {!loading &&
        (availabilities ? (
          availabilities.length > 0 ? (
            <List disablePadding>
              {availabilities.map(({ location, availability, callNumber, link }) => (
                <ListItem dense key={location?.code} disableGutters>
                  <ListItemButton
                    LinkComponent="a"
                    href={link ?? "#"}
                    sx={{ borderRadius: 1 }}
                  >
                    <ListItemAvatar>
                      <LibraryIcon color="action" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          <b>{location?.name}</b> – {availability}
                        </>
                      }
                      secondary={`${location?.section} (${callNumber})`}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body2" color="GrayText">
              Not available at any library
            </Typography>
          )
        ) : (
          <Typography variant="body2" color="GrayText">
            No library availability information available
          </Typography>
        ))}
    </>
  );
}
