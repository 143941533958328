import { useEffect } from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useSearchBooks } from "~/data/search";
import { useLoadResource } from "~/components/core/AppLoadingBar";
import { BooklistBooks } from "~/components/booklist/BooklistBooks";
import {
  PreviewSearchTypeLabel,
  usePreviewSearch,
} from "~/components/search/PreviewSearchField";
import { SearchErrorAlert } from "~/components/search/SearchErrorAlert";
import { SearchInProgressLabel } from "~/components/search/SearchInProgressLabel";

import { PreviewSearchResultsStudentCard } from "./PreviewSearchResultsStudentCard";

export function PreviewSearchResults() {
  const [type = "unitCode", value] = usePreviewSearch();

  const { isFetching, data, error } = useSearchBooks({ [type]: value });
  const [load, unload] = useLoadResource(PreviewSearchResults.name);

  useEffect(() => {
    if (isFetching) load();
    else unload();
  }, [isFetching, value]);

  if (isFetching) return <SearchInProgressLabel />;

  if (error) return <SearchErrorAlert error={error} />;

  if (!data)
    return (
      <Typography variant="body2" color="GrayText">
        Please provide a valid {PreviewSearchTypeLabel[type]}, then press enter to search.
      </Typography>
    );

  const { booklists = [], student, enrolment } = data ?? {};

  const books = booklists.map((booklist) => booklist.books).flat();

  if (books.length === 0)
    return (
      <Typography variant="body2" color="GrayText">
        No results returned for {PreviewSearchTypeLabel[type]} "{value}".
      </Typography>
    );

  return (
    <Stack spacing={2}>
      {student && <PreviewSearchResultsStudentCard student={student} />}
      <BooklistBooks booklists={booklists} enrolment={enrolment} />
    </Stack>
  );
}
