/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ActiveEnrolmentRecords } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class EnrolmentsApi<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Enrolments API
   * @name GetEnrolments
   * @summary Get the current student's enrolments
   * @request GET:/api/enrolments
   * @response `200` `ActiveEnrolmentRecords`
   */
  getEnrolments = (
    query?: {
      forceRefresh?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<ActiveEnrolmentRecords, any>({
      path: `/api/enrolments`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}
