import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { BooksApi, SearchApi, useApi } from "~/api";

export function useSearchBooks(
  options: {
    email?: string;
    studentId?: string;
    unitCode?: string;
  } = {},
) {
  const { email, studentId, unitCode } = options;
  const api = useApi(BooksApi);
  return useQuery({
    queryKey: ["search", "books", options],
    queryFn: () => api.getBooks({ email, studentId, unitCode }),
    retry: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    enabled: Boolean(email || studentId || unitCode),
  });
}

export function useSearchUnitStudents(options: { unitCode?: string } = {}) {
  const { unitCode = "" } = options;
  const api = useApi(SearchApi);
  return useQuery({
    queryKey: ["search", "units", options],
    queryFn: () => api.searchStudentByUnit(unitCode),
    retry: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    enabled: Boolean(unitCode),
  });
}
