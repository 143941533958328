import { useMemo } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import Alert from "@mui/material/Alert";

import InfoIcon from "@mui/icons-material/InfoOutlined";

import { titleCase } from "@packages/utils";

import { useStyles } from "~/utils/styles";
import { BooklistBook, Bookshop } from "~/api";
import { FontFamily } from "~/config/theme";
import { useGetMyBuyList } from "~/data/buy-list";
import { useGetBookshops } from "~/data/bookshops";
import { BooklistBooksItem } from "~/components/booklist/BooklistBooks/BooklistBooksItem";
import { useBookshopInfoDialog } from "~/components/booklist/BookshopInfoDialog";

const PLACEHOLDER = (
  <Paper>
    <Stack px={3} py={2}>
      <Box display="flex" mb={1}>
        <Typography variant="h5">
          <Skeleton width={300} />
        </Typography>
      </Box>
      <Divider />
      <List>
        <BooklistBooksItem />
        <BooklistBooksItem />
        <BooklistBooksItem />
      </List>
      <Divider />
      <Box mt={2} gap={2}>
        <Typography variant="body2">
          <Skeleton width={300} />
        </Typography>
      </Box>
    </Stack>
  </Paper>
);

export function BuyList() {
  const { below, palette } = useStyles();

  const [, setBookshopInfoOpen, , setBookshopInfo] = useBookshopInfoDialog();
  const { data: buyList, isLoading: buyListIsLoading } = useGetMyBuyList();
  const { data: bookshops, isLoading: bookshopsIsLoading } = useGetBookshops();

  const booksByBookshop = useMemo(() => {
    const booksByBookshop: Record<
      string,
      { bookshop?: Bookshop; books: BooklistBook[] }
    > = {};
    for (const book of buyList ?? []) {
      const bookshop = bookshops?.find((bookshop) => bookshop.id === book.bookshopId);
      booksByBookshop[book.booklistId] ||= { bookshop, books: [] };
      booksByBookshop[book.booklistId].books.push(book);
    }
    return Object.entries(booksByBookshop);
  }, [buyList, bookshops]);

  if (buyListIsLoading || bookshopsIsLoading) return PLACEHOLDER;

  if (booksByBookshop.length === 0)
    return <Alert severity="info">You have no books in your buy list.</Alert>;

  const elements = booksByBookshop.map(([bookshopId, { bookshop, books }]) => (
    <Paper key={bookshopId}>
      <Stack px={3} py={2}>
        <Box
          display="flex"
          mb={1}
          sx={{
            justifyContent: "space-between",
            [below.sm]: {
              flexWrap: "wrap",
              justifyContent: "flex-start",
            },
          }}
        >
          <Typography
            variant="h5"
            fontFamily={FontFamily.condensed}
            sx={{
              [below.sm]: {
                textAlign: "center",
                width: "100%",
              },
            }}
          >
            {bookshop?.name ?? "UNAVAILABLE"}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            sx={{
              [below.sm]: {
                width: "100%",
                justifyContent: "flex-start",
                flexDirection: "row-reverse",
              },
            }}
          >
            <Button
              color="secondary"
              endIcon={<InfoIcon color="action" />}
              sx={{
                textTransform: "none",
                color: palette.grey[600],
                [below.sm]: { width: "100%" },
              }}
              onClick={() => {
                if (!bookshop) return;
                setBookshopInfo(bookshop);
                setBookshopInfoOpen(true);
              }}
            >
              {bookshop?.locations.map((l) => titleCase(l.campus)).join(", ")}
            </Button>
          </Box>
        </Box>
        <Divider />
        <List>
          {books.map((book) => (
            <BooklistBooksItem key={book.id} book={book} showUnitCode />
          ))}
        </List>
        <Divider />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
          gap={2}
          sx={{ [below.sm]: { flexDirection: "column" } }}
        >
          <Typography variant="body2" color="GrayText">
            If a book does not show up in your cart, it may not be available at the book
            shop.
          </Typography>
          <Button
            variant="contained"
            sx={{ flexShrink: 0, [below.sm]: { width: "100%" } }}
            onClick={() => {
              const { buyUrl, buyUrlItem } = bookshop ?? {};
              if (!buyUrl || !buyUrlItem) return;
              const params = books
                .map((book) => buyUrlItem.replaceAll("{isbn}", book.isbn))
                .join("&");
              window.location.href = buyUrl + "?" + params;
            }}
          >
            GO TO STORE
          </Button>
        </Box>
      </Stack>
    </Paper>
  ));

  return <>{elements}</>;
}
