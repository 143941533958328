import { useLocation } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";

import { capitalise } from "@packages/utils";

import { useIsResourceLoading, useLoadResource } from "~/components/core/AppLoadingBar";
import { Title } from "~/components/core/Title";

const SIGN_IN_URL = "/api/auth/oauth2/sign-in";

export function SignInForm() {
  const search = new URLSearchParams(useLocation().search);
  const error = search.get("error");
  const message = search.get("message");

  const [load] = useLoadResource();
  const loading = useIsResourceLoading();

  return (
    <Paper
      elevation={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 480,
        p: 3,
        m: "auto",
        mt: 12,
        mb: 12,
      }}
    >
      <Title>
        <b>MONASH</b> STUDENT BOOKLIST
      </Title>
      <Typography variant="body1" color="textSecondary">
        Please sign in with your Monash account to continue.
      </Typography>
      <Button
        fullWidth
        sx={{ mt: 2 }}
        variant="contained"
        color="primary"
        size="large"
        href={SIGN_IN_URL}
        disabled={loading}
        onClick={load}
      >
        Sign In
      </Button>
      {error && (
        <Typography
          variant="body2"
          color="error"
          sx={{ mt: 2, alignSelf: "flex-start" }}
          component="div"
        >
          <b>{capitalise(error)} error</b>
          <div>{message}</div>
        </Typography>
      )}
    </Paper>
  );
}
