/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ErrorResponse, UserInfo } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class AuthApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Returns the currently signed in user's info.
   *
   * @tags Auth API
   * @name GetUser
   * @summary Get user credentials
   * @request GET:/api/auth/user
   * @response `200` `UserInfo` Returns the current user.
   * @response `401` `ErrorResponse` Not signed in.
   */
  getUser = (params: RequestParams = {}) =>
    this.request<UserInfo, ErrorResponse>({
      path: `/api/auth/user`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Sign out of the current user's account.
   *
   * @tags Auth API
   * @name SignOut
   * @summary Sign out
   * @request POST:/api/auth/sign-out
   * @response `200` `void` Signed out successfully.
   * @response `401` `ErrorResponse` Not signed in.
   */
  signOut = (params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/api/auth/sign-out`,
      method: "POST",
      ...params,
    });
}
