import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useMenuAnchor } from "~/utils/menu";
import { useBooklistInfoDialog } from "~/components/booklist/BooklistInfoDialog";
import { useSelectedBooklist } from "~/components/booklist/BooklistSelect";
import {
  useBooklistDeleteDialog,
  useIsBooklistDeleting,
} from "~/components/booklist/BooklistDeleteDialog";

export function BooklistOptions() {
  const [anchor, onOpen, onClose] = useMenuAnchor();
  const [, setInfoOpen] = useBooklistInfoDialog();
  const [, setDeleteOpen] = useBooklistDeleteDialog();
  const booklist = useSelectedBooklist();
  const deleting = useIsBooklistDeleting();
  const disabled = !booklist || deleting;
  return (
    <>
      <IconButton
        aria-label="options"
        size="large"
        sx={{ justifySelf: "center" }}
        onClick={onOpen}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={onClose}>
        <MenuItem
          onClick={() => {
            setInfoOpen(true);
            onClose();
          }}
        >
          Booklist details
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDeleteOpen(true);
            onClose();
          }}
        >
          Delete booklist
        </MenuItem>
      </Menu>
    </>
  );
}
