import { BookshopSelect } from "~/components/booklist/BookshopSelect";

import { useBooklistUploadStatus } from "./BooklistUpload";
import { BooklistUploadYearSelect } from "./BooklistUploadYearSelect";

export function BooklistUploadOptions() {
  const [status] = useBooklistUploadStatus();
  if (status === "success") return null;
  return (
    <>
      <BookshopSelect disabled={status === "pending"} />
      <BooklistUploadYearSelect disabled={status === "pending"} />
    </>
  );
}
