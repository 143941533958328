import { useMemo } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Alert from "@mui/material/Alert";
import Skeleton from "@mui/material/Skeleton";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { BooklistBook, BooklistSearchResponse } from "~/api";
import { useStyles } from "~/utils/styles";

import { BooklistBooksItem } from "./BooklistBooksItem";
import { groupBooksByChoice, sortBooksByType } from "./utils";

export interface BooklistBooksProps {
  placeholders?: boolean;
  useBuyList?: boolean;
  booklists?: BooklistSearchResponse["booklists"];
  enrolment?: BooklistSearchResponse["enrolment"];
}

const UNIT_PLACEHOLDER = (
  <Box>
    <Typography
      variant="h6"
      color="primary"
      mb={2}
      display="flex"
      alignItems="center"
      flexWrap="wrap"
      rowGap={1}
      columnGap={2}
    >
      <Typography variant="h6" component="span">
        <Skeleton width={80} />
      </Typography>
      <Typography variant="h6" component="span">
        <Skeleton width={240} />
      </Typography>
    </Typography>
    <BooklistBooksItem />
    <BooklistBooksItem />
    <BooklistBooksItem />
    <BooklistBooksItem />
    <Divider sx={{ mt: 4, mb: 4 }} />
  </Box>
);

const PLACEHOLDER = (
  <Accordion defaultExpanded>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h6" display="flex">
        <Skeleton variant="text" sx={{ mr: 2 }} width={45} />
        <Skeleton variant="text" width={200} />
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      {UNIT_PLACEHOLDER}
      {UNIT_PLACEHOLDER}
      {UNIT_PLACEHOLDER}
      {UNIT_PLACEHOLDER}
    </AccordionDetails>
  </Accordion>
);

export function BooklistBooks(props: BooklistBooksProps) {
  const { placeholders, booklists, enrolment, useBuyList } = props;
  const { below, palette, lighten } = useStyles();

  const sortedBooks = useMemo(() => {
    if (!booklists) return [];

    const sortedBooks: {
      [teachingPeriod: string]: {
        year: string;
        units: {
          [unitCode: string]: {
            title?: string;
            status?: string;
            books: BooklistBook[];
          };
        };
      };
    } = {};

    for (const { id, bookshopId, books, year } of booklists) {
      for (const book of books) {
        const tp = book.teachingPeriodTitle ?? book.teachingPeriod;
        const unitCode = book.unitCode;
        sortedBooks[tp] ||= { year, units: {} };
        sortedBooks[tp].units[unitCode] ||= { title: book.unitTitle, books: [] };
        sortedBooks[tp].units[unitCode].books.push({
          booklistId: id,
          bookshopId,
          ...book,
        });
      }
    }

    // If enrolments are provided, add units that don't have books
    // with an empty list so that they are still shown to the user
    for (const enrolmentRecord of Object.values(enrolment ?? {})) {
      const { status, year, teachingPeriodTitle, unitCode, unitTitle } = enrolmentRecord;
      const tp = teachingPeriodTitle;
      sortedBooks[tp] ||= { year, units: {} };
      sortedBooks[tp].units[unitCode] ||= { title: unitTitle, books: [] };
      sortedBooks[tp].units[unitCode].status = status;
    }

    Object.values(sortedBooks).forEach((teachingPeriod) =>
      Object.values(teachingPeriod.units).forEach((unit) => {
        unit.books = sortBooksByType(unit.books);
      }),
    );

    return Object.entries(sortedBooks);
  }, [booklists]);

  return (
    <Stack>
      {sortedBooks.length === 0 &&
        (placeholders ? (
          PLACEHOLDER
        ) : (
          <Alert severity="info">You are not currently enrolled in any units.</Alert>
        ))}
      {sortedBooks.map(([title, { year, units }]) => (
        <Accordion key={title} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">
              <Typography variant="h6" component="span" fontWeight="bold" sx={{ mr: 2 }}>
                {year}
              </Typography>
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {Object.entries(units).map(([unitCode, { title, books, status }], index) => (
              <Box key={unitCode}>
                <Typography
                  variant="h6"
                  color="primary"
                  mb={2}
                  display="flex"
                  alignItems="center"
                  flexWrap="wrap"
                  rowGap={1}
                  columnGap={2}
                >
                  <Typography variant="h6" component="span" fontWeight="bold">
                    {unitCode}
                  </Typography>
                  <Typography variant="h6" component="span">
                    {title}
                  </Typography>
                  {status && (
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ [below.sm]: { width: "100%" } }}
                    >
                      <Chip label={status} size="small" />
                    </Box>
                  )}
                </Typography>
                {books.length === 0 && (
                  <Typography variant="body2" color="GrayText" mb={1}>
                    No books listed for this unit
                  </Typography>
                )}
                {groupBooksByChoice(books).map(({ id, choices }) => {
                  if (choices.length > 1)
                    return (
                      <Box key={id} my={2}>
                        <Typography variant="button" color="textSecondary">
                          Choice of one of the following
                        </Typography>
                        <List
                          disablePadding
                          sx={{
                            mt: 2,
                            ml: 2,
                            borderLeftStyle: "solid",
                            borderLeftWidth: 4,
                            borderLeftColor: lighten(palette.primary.main, 0.6),
                          }}
                        >
                          {choices.map((book) => (
                            <BooklistBooksItem
                              key={book.id}
                              book={book}
                              useBuyList={useBuyList}
                            />
                          ))}
                        </List>
                      </Box>
                    );
                  const book = choices[0];
                  return (
                    <BooklistBooksItem
                      key={book.id}
                      book={book}
                      useBuyList={useBuyList}
                    />
                  );
                })}
                {index < Object.entries(units).length - 1 && (
                  <Divider sx={{ mt: 4, mb: 4 }} />
                )}
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
}
