/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { BooklistBook, ErrorResponse } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class BuyListsApi<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Buy Lists API
   * @name GetBuyList
   * @summary Get books from the current student's buy list
   * @request GET:/api/buy-list
   * @response `200` `(BooklistBook)[]`
   */
  getBuyList = (params: RequestParams = {}) =>
    this.request<BooklistBook[], any>({
      path: `/api/buy-list`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Buy Lists API
   * @name AddToBuyList
   * @summary Add a book to the current student's buy list
   * @request POST:/api/buy-list/{bookId}
   * @response `200` `void`
   * @response `404` `ErrorResponse` Book not found
   */
  addToBuyList = (bookId: string, params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/api/buy-list/${bookId}`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags Buy Lists API
   * @name RemoveFromBuyList
   * @summary Remove a book from the current student's buy list
   * @request DELETE:/api/buy-list/{bookId}
   * @response `200` `void`
   */
  removeFromBuyList = (bookId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/buy-list/${bookId}`,
      method: "DELETE",
      ...params,
    });
}
