import { Book } from "~/api";

type BookType = Book["type"];

export type BookChoices<T extends Book = Book> = {
  id: string;
  choices: T[];
};

// List book types in order that they should be sorted
const order: BookType[] = [
  "Prescribed",
  "Highly Recommended",
  "Recommended",
  "Course Notes",
  "Reference",
  "Discretionary",
  "Value Pack",
  "Unspecified",
];

// Convert the list into a mapping book type to order index
const mapping = order.reduce(
  (map, value, index) => {
    map[value] = order.length - index;
    return map;
  },
  {} as { [key in BookType]: number },
);

/**
 * Sort books based on type.
 */
export function sortBooksByType<T extends Book>(books: T[]): T[] {
  return books.sort((a, b) => mapping[b.type] - mapping[a.type]);
}

/**
 * Group books that have the same ISBN or `choiceOfText` value.
 */
export function groupBooksByChoice<T extends Book>(books: T[]): BookChoices<T>[] {
  const bookChoiceMap: { [choice: string]: T[] } = {};

  for (const book of books) {
    const choice = book.choiceOfText || book.isbn;
    if (!(choice in bookChoiceMap)) bookChoiceMap[choice] = [];
    bookChoiceMap[choice].push(book);
  }

  return Object.entries(bookChoiceMap).map(([id, choices]) => ({ id, choices }));
}
