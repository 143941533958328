import { useEffect } from "react";
import { atom, useAtom, useAtomValue } from "jotai";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { useIsBooklistDeleting } from "~/components/booklist/BooklistDeleteDialog";
import { useGetBookshops } from "~/data/bookshops";

const SelectedBookshopId = atom("");

export function useSelectedBookshop() {
  const bookshopId = useAtomValue(SelectedBookshopId);
  const { data: bookshops } = useGetBookshops();
  return bookshops?.find((bookshop) => bookshop.id === bookshopId) ?? null;
}

export interface BookshopSelectProps {
  disabled?: boolean;
}

export function BookshopSelect(props: BookshopSelectProps) {
  const { disabled: disabledOverride } = props;

  const [bookshopId, setBookshopId] = useAtom(SelectedBookshopId);

  const { data, isLoading } = useGetBookshops();
  const deleting = useIsBooklistDeleting();
  const disabled = isLoading || deleting || disabledOverride;

  useEffect(() => {
    if (!data) setBookshopId("");
    else if (!bookshopId || !data.find((bookshop) => bookshop.id === bookshopId)) {
      const matchedBookshopId = data[0]?.id;
      if (matchedBookshopId) setBookshopId(matchedBookshopId);
    }
  }, [data, bookshopId]);

  return (
    <FormControl fullWidth size="small" disabled={disabled || data?.length === 0}>
      <InputLabel id="bookshop-select-label">Bookshop</InputLabel>
      <Select
        labelId="bookshop-select-label"
        id="bookshop-select"
        label="Boookshop"
        value={bookshopId}
        onChange={(e) => setBookshopId(e.target.value)}
      >
        {data?.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
