import { atom, useAtom } from "jotai";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import { titleCase } from "@packages/utils";

import { Bookshop } from "~/api";

const BookshopInfoDialogOpen = atom(false);
const SelectedBookshopInfo = atom<Bookshop | null>(null);

export function useBookshopInfoDialog() {
  return [...useAtom(BookshopInfoDialogOpen), ...useAtom(SelectedBookshopInfo)] as const;
}

export function BookshopInfoDialog() {
  const [open, setOpen, bookshop] = useBookshopInfoDialog();
  const { name, url, locations } = bookshop ?? {};
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth>
      <DialogTitle>{name}</DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          {url && (
            <Box>
              <Typography
                component="p"
                variant="overline"
                color="GrayText"
                lineHeight={2}
              >
                Website
              </Typography>
              <Link href={url}>{new URL(url).host}</Link>
            </Box>
          )}
          <Divider sx={{ width: "100%" }} />
          {locations?.map(({ campus, email, phone, address }) => (
            <Stack key={campus} gap={1}>
              <Typography component="p" variant="body1" fontWeight="bold">
                {titleCase(campus)}
              </Typography>
              {address && (
                <Typography variant="body2" component={Stack}>
                  {address.split(",").map((line) => (
                    <span key={line}>{line}</span>
                  ))}
                </Typography>
              )}
              {email && <Link href={`mailto:${email}`}>{email}</Link>}
              {phone && <Link href={`tel:${phone}`}>{phone}</Link>}
              <Divider sx={{ width: "100%", mt: 2 }} />
            </Stack>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={() => setOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
