import Box from "@mui/material/Box";

import { useStyles } from "~/utils/styles";
import { Title } from "~/components/core/Title";
import { EventList } from "~/components/events/EventList";
import { EventTypeSelect } from "~/components/events/EventTypeSelect";

export function AdminEvents() {
  const { below } = useStyles();
  return (
    <>
      <Title>
        <b>AUDIT</b> LOG
      </Title>
      <Box display="flex" flexDirection="column" gap={2} mt={4}>
        <Box width={320} sx={{ [below.sm]: { width: "100%" } }}>
          <EventTypeSelect />
        </Box>
        <Box mt={2}>
          <EventList />
        </Box>
      </Box>
    </>
  );
}
