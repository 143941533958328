import { atom, useAtom } from "jotai";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import { useSelectedBooklist } from "~/components/booklist/BooklistSelect";
import { useDeleteBooklist } from "~/data/booklists";
import { useIsResourceLoading } from "~/components/core/AppLoadingBar";

const BooklistDeleteDialogOpen = atom(false);

export function useBooklistDeleteDialog() {
  return useAtom(BooklistDeleteDialogOpen);
}

export function useIsBooklistDeleting() {
  return useIsResourceLoading(BooklistsDeleteDialog.name);
}

export function BooklistsDeleteDialog() {
  const [open, setOpen] = useBooklistDeleteDialog();
  const booklist = useSelectedBooklist();
  const deleteBooklist = useDeleteBooklist(BooklistsDeleteDialog.name);
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs">
      <DialogTitle>Delete booklist</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete the current booklist and all associated books?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            setOpen(false);
            if (booklist) deleteBooklist.mutate(booklist.id);
          }}
        >
          Delete
        </Button>
        <Button color="inherit" onClick={() => setOpen(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
