/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Book,
  BookAvailabilities,
  BookDetails,
  BooklistSearchResponse,
  ErrorResponse,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class BooksApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description If the current user is a student, their books are returned. Otherwise, specify a student's email, ID or a unit code to get the corresponding books.
   *
   * @tags Books API
   * @name GetBooks
   * @summary Get the books for a student or a unit
   * @request GET:/api/books
   * @response `200` `BooklistSearchResponse`
   * @response `400` `ErrorResponse` Invalid filters provided
   * @response `404` `ErrorResponse` User with specified identifier not found
   */
  getBooks = (
    query?: {
      email?: string;
      studentId?: string;
      unitCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<BooklistSearchResponse, ErrorResponse>({
      path: `/api/books`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Books API
   * @name GetBook
   * @summary Get a book
   * @request GET:/api/books/{bookId}
   * @response `200` `Book`
   * @response `404` `ErrorResponse` Book not found
   */
  getBook = (bookId: string, params: RequestParams = {}) =>
    this.request<Book, ErrorResponse>({
      path: `/api/books/${bookId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Books API
   * @name GetBookDetails
   * @summary Get a book's details from the Google Books API
   * @request GET:/api/books/{bookId}/details
   * @response `200` `BookDetails`
   * @response `404` `ErrorResponse` Book not found
   */
  getBookDetails = (bookId: string, params: RequestParams = {}) =>
    this.request<BookDetails, ErrorResponse>({
      path: `/api/books/${bookId}/details`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Books API
   * @name GetBookAvailabilities
   * @summary Get a book's library availability
   * @request GET:/api/books/{bookId}/availabilities
   * @response `200` `BookAvailabilities`
   * @response `404` `ErrorResponse` Book not found
   */
  getBookAvailabilities = (bookId: string, params: RequestParams = {}) =>
    this.request<BookAvailabilities, ErrorResponse>({
      path: `/api/books/${bookId}/availabilities`,
      method: "GET",
      format: "json",
      ...params,
    });
}
