import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import ExternalIcon from "@mui/icons-material/OpenInNew";

import { useGetBookshop } from "~/data/bookshops";

export interface BookInfoDialogBuyProps {
  isbn?: string;
  bookshopId?: string;
}

export function BookInfoDialogBuyNow(props: BookInfoDialogBuyProps) {
  const { isbn = "", bookshopId } = props;

  const { isLoading, data } = useGetBookshop(bookshopId);

  return (
    <Button
      variant="contained"
      fullWidth
      component="a"
      target="_blank"
      referrerPolicy="no-referrer"
      rel="noopener"
      disabled={isLoading}
      href={data?.bookUrl.replaceAll("{isbn}", isbn)}
    >
      <Typography mr={2}>Buy now</Typography>
      <ExternalIcon fontSize="small" />
    </Button>
  );
}
