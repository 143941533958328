import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

import { version } from "@packages/version";

import { useStyles } from "~/utils/styles";

import monashLogo from "~/assets/monash-light.png";

/**
 * Renders the footer information and links.
 */
export function Footer() {
  const { below, FontFamily, Monash } = useStyles();
  return (
    <Box
      mt="auto"
      p={3}
      display="flex"
      justifyContent="center"
      width="100vw"
      color={Monash.footerText}
      sx={{ backgroundColor: Monash.mediumGray }}
    >
      <Container maxWidth="md">
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          flexWrap="wrap"
          mb={2}
        >
          <Link href="https://monash.edu">
            <Box
              component="img"
              src={monashLogo}
              alt="Monash University"
              sx={{ width: 200, mb: 1, [below.sm]: { width: 150 } }}
            />
          </Link>
          <Typography variant="h6" fontFamily={FontFamily.condensed}>
            <b>MONASH</b> STUDENT BOOKLIST
          </Typography>
        </Box>
        <Typography variant="body1" mb={2}>
          <b>
            We acknowledge and pay respects to the Elders and Traditional Owners of the
            land on which our four Australian campuses stand.{" "}
            <Link
              href="https://www.monash.edu/indigenous-australians"
              color="#fff"
              underline="always"
            >
              Information for Indigenous Australians
            </Link>
          </b>
        </Typography>
        <Typography variant="subtitle2">
          Authorised by: eSolutions. Maintained by: eSolutions, Monash University. Version{" "}
          {version}.
        </Typography>
        <Typography variant="subtitle2">
          Copyright &copy; {new Date().getFullYear()}{" "}
          <Link href="https://monash.edu" color="#fff" underline="always">
            Monash University
          </Link>{" "}
          and{" "}
          <Link href="https://www.monashcollege.edu.au" color="#fff" underline="always">
            Monash College
          </Link>
          . ABN 12 377 614 012{" "}
          <Link
            href="https://www.monash.edu/accessibility"
            color="#fff"
            underline="always"
          >
            Accessibility
          </Link>{" "}
          -{" "}
          <Link
            href="https://www.monash.edu/disclaimer-copyright"
            color="#fff"
            underline="always"
          >
            Disclaimer and Copyright
          </Link>{" "}
          -{" "}
          <Link
            href="https://www.monash.edu/privacy-monash"
            color="#fff"
            underline="always"
          >
            Data Protection and Privacy Procedure
          </Link>
          , Monash University CRICOS Provider Number: 00008C, Monash College CRICOS
          Provider Number: 01857J. Monash University is a registered higher education
          provider under the TEQSA Act 2011.
        </Typography>
      </Container>
    </Box>
  );
}
