import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useStyles } from "~/utils/styles";

export interface BookInfoDialogThumbnailProps {
  loading?: boolean;
  url?: string;
}

export function BookInfoDialogThumbnail(props: BookInfoDialogThumbnailProps) {
  const { loading, url } = props;
  const { palette, shadows, shape } = useStyles();

  return (
    <Box display="flex" justifyContent="center" my={2}>
      <Box
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={150}
        height={200}
        overflow="hidden"
        bgcolor={palette.grey[100]}
        boxShadow={shadows[8]}
        borderRadius={shape.borderRadius / 4}
      >
        {url && <Box height="100%" width="100%" component="img" src={url} />}
        {!loading && !url && (
          <Typography variant="body2" color="GrayText">
            Image unavailable
          </Typography>
        )}
      </Box>
    </Box>
  );
}
