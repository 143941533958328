import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Hidden from "@mui/material/Hidden";
import Skeleton from "@mui/material/Skeleton";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";

import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";

import { BooklistBook } from "~/api";
import { useStyles } from "~/utils/styles";
import { useInBuyList, useUpdateBuyList } from "~/data/buy-list";
import { useBookInfoDialog } from "~/components/book/BookInfoDialog";
import { BookTypeIcon } from "~/components/book/BookTypeIcon";

export interface BooklistBooksItemProps {
  book?: BooklistBook;
  showUnitCode?: boolean;
  useBuyList?: boolean;
}

export function BooklistBooksItem(props: BooklistBooksItemProps) {
  const { book, showUnitCode, useBuyList } = props;

  const { below } = useStyles();
  const { openBookDialog } = useBookInfoDialog();

  const { book: buyListBook } = useInBuyList(book?.id, !useBuyList);
  const updateBuyList = useUpdateBuyList(BooklistBooksItem.name);

  return (
    <ListItem disablePadding>
      <ListItemButton
        disableRipple
        sx={{ borderRadius: 1 }}
        onClick={() => book && openBookDialog(book)}
      >
        <ListItemText
          sx={{ pr: 12, mt: 0 }}
          primary={
            book ? (
              <Box>
                <Typography component="span" display="flex" alignItems="center" gap={1}>
                  <BookTypeIcon type={book?.type} size="small" />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    lineHeight={1}
                    textTransform="uppercase"
                  >
                    {book?.type}
                  </Typography>
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {showUnitCode && <>{book?.unitCode} – </>}
                  {book?.title}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Skeleton width={100} />
                <Skeleton
                  sx={{
                    width: 300,
                    [below.sm]: { width: 180 },
                  }}
                />
              </Box>
            )
          }
          secondary={
            book ? (
              <>
                {book?.author || "No author listed"}
                {book?.edition ? ` – ${book.edition}` : ""}
              </>
            ) : (
              <Skeleton width={100} />
            )
          }
        />
        <ListItemSecondaryAction>
          {book ? (
            <Stack gap={1} alignItems="flex-end">
              <Typography variant="body2">
                <Hidden mdDown>AUD</Hidden> ${parseFloat(book.price).toFixed(2)}
              </Typography>
              {useBuyList && (
                <Chip
                  label="Buy list"
                  icon={
                    updateBuyList.isPending ? (
                      <CircularProgress size={24} thickness={7} sx={{ p: 0.2 }} />
                    ) : buyListBook ? (
                      <CheckIcon />
                    ) : (
                      <AddIcon />
                    )
                  }
                  color={buyListBook ? "success" : "default"}
                  clickable
                  onClick={(e) => {
                    e.stopPropagation();
                    updateBuyList.mutate({
                      bookId: book?.id,
                      action: buyListBook ? "remove" : "add",
                    });
                  }}
                />
              )}
            </Stack>
          ) : (
            <Typography variant="body2">
              <Skeleton width={60} />
            </Typography>
          )}
        </ListItemSecondaryAction>
      </ListItemButton>
    </ListItem>
  );
}
