import PrescribedIcon from "@mui/icons-material/CheckCircle";
import NotesIcon from "@mui/icons-material/Assignment";
import HighlyRecommendedIcon from "@mui/icons-material/CheckCircleOutline";
import RecommendedIcon from "@mui/icons-material/Check";
import ReferenceIcon from "@mui/icons-material/LibraryBooks";
import ValuePackIcon from "@mui/icons-material/Stars";
import DiscretionaryIcon from "@mui/icons-material/InfoOutlined";
import UnknownIcon from "@mui/icons-material/HelpOutline";

import { Book } from "~/api";
import { useStyles } from "~/utils/styles";

export interface BookTypeIconProps {
  className?: string;
  type?: Book["type"];
  size?: "medium" | "small";
}

/**
 * Display the specified book type as an icon with a tooltip.
 */
export function BookTypeIcon({ className, type, size = "medium" }: BookTypeIconProps) {
  const { palette } = useStyles();

  let Icon = UnknownIcon;
  let color = palette.secondary.light;

  if (type === "Prescribed") {
    Icon = PrescribedIcon;
    color = palette.success.main;
  } else if (type === "Highly Recommended") {
    Icon = HighlyRecommendedIcon;
    color = palette.success.main;
  } else if (type === "Recommended") {
    Icon = RecommendedIcon;
    color = palette.success.main;
  } else if (type === "Course Notes") {
    Icon = NotesIcon;
  } else if (type === "Reference") {
    Icon = ReferenceIcon;
  } else if (type === "Value Pack") {
    Icon = ValuePackIcon;
  } else if (type === "Discretionary") {
    Icon = DiscretionaryIcon;
  }

  return (
    <Icon
      className={className}
      sx={{ color, width: size === "small" ? 16 : undefined }}
    />
  );
}
