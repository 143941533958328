import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

import { useStyles } from "~/utils/styles";
import { useGetMyBooklist } from "~/data/book";
import { useGetMyBuyList } from "~/data/buy-list";
import { Title } from "~/components/core/Title";
import { BooklistBooks } from "~/components/booklist/BooklistBooks";
import { BookInfoDialog } from "~/components/book/BookInfoDialog";

export function StudentBooks() {
  const booklist = useGetMyBooklist();
  const buyList = useGetMyBuyList();

  const { below } = useStyles();

  const action = (
    <Button
      endIcon={
        <Badge
          variant="dot"
          color="error"
          invisible={!buyList.data || buyList.data.length === 0}
        >
          <ShoppingBagIcon />
        </Badge>
      }
      component={Link}
      to="/buy"
      sx={{ [below.sm]: { width: "100%" } }}
    >
      View buy list
    </Button>
  );

  return (
    <>
      <Title subtitle="Find the books for your units below." action={action}>
        <b>MONASH</b> STUDENT BOOKLIST
      </Title>
      {booklist.isLoading && <BooklistBooks placeholders />}
      {!booklist.isLoading && (
        <BooklistBooks
          booklists={booklist.data?.booklists}
          enrolment={booklist.data?.enrolment}
          useBuyList
        />
      )}
      <BookInfoDialog canAddToBuyList />
    </>
  );
}
