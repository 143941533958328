import { AxiosError } from "axios";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export interface SearchErrorAlertProps {
  error?: unknown;
}

export function SearchErrorAlert(props: SearchErrorAlertProps) {
  const { error } = props;

  if (!error) return null;

  let errorTitle = "Search error";
  let errorMessage = "Failed to search";

  if (error instanceof AxiosError) {
    errorTitle = error.response?.data?.code || errorTitle;
    errorMessage = error.response?.data?.message || errorMessage;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  }

  return (
    <Alert severity="error">
      <AlertTitle>
        <b>{errorTitle}</b>
      </AlertTitle>
      {errorMessage}
    </Alert>
  );
}
