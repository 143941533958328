import { useCallback } from "react";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import useMediaQuery from "@mui/material/useMediaQuery";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Hidden from "@mui/material/Hidden";

import { capitalise } from "@packages/utils";

import { BooklistBook } from "~/api";
import { useStyles } from "~/utils/styles";
import { useGetBookAvailabilities, useGetBookDetails } from "~/data/book";
import { BookTypeIcon } from "~/components/book/BookTypeIcon";

import { BookInfoDialogDetails } from "./BookInfoDialogDetails";
import { BookInfoDialogAvailabilities } from "./BookInfoDialogAvailabilities";
import { BookInfoDialogThumbnail } from "./BookInfoDialogThumbnail";
import { BookInfoDialogBuyNow } from "./BookInfoDialogBuyNow";
import { BookInfoDialogBuyList } from "./BookInfoDialogBuyList";

const BookDialogOpen = atom(false);
const SelectedBook = atom<BooklistBook | null>(null);

export function useBookInfoDialog() {
  const setOpen = useSetAtom(BookDialogOpen);
  const setSelectedBook = useSetAtom(SelectedBook);

  const openBookDialog = useCallback((book: BooklistBook) => {
    setOpen(true);
    setSelectedBook(book);
  }, []);

  const closeBookDialog = useCallback(() => {
    setOpen(false);
  }, []);

  return { openBookDialog, closeBookDialog };
}

export function useSelectedBook() {
  return useAtomValue(SelectedBook);
}

export interface BookInfoDialogProps {
  canAddToBuyList?: boolean;
}

export function BookInfoDialog(props: BookInfoDialogProps) {
  const { canAddToBuyList } = props;

  const { below } = useStyles();
  const mobile = useMediaQuery(below.md);

  const [open, setOpen] = useAtom(BookDialogOpen);
  const {
    id,
    title,
    author,
    campus,
    edition,
    unitCode,
    teachingPeriod,
    price,
    type,
    isbn,
    choiceOfText,
    bookshopId,
  } = useAtomValue(SelectedBook) ?? {};

  const details = useGetBookDetails(id);
  const availabilities = useGetBookAvailabilities(id);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="md"
      fullScreen={mobile}
    >
      <DialogTitle fontWeight="bold">{title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <BookInfoDialogThumbnail
                  loading={details.isLoading}
                  url={details.data?.details?.thumbnail}
                />
              </Grid>
              {[
                {
                  label: "Type",
                  value: (
                    <>
                      <BookTypeIcon type={type} /> {type}
                    </>
                  ),
                },
                { label: "Recommended Price", value: `AUD $${price}` },
                { label: "ISBN", value: isbn },
                { label: "Location", value: capitalise(campus ?? "") },
              ].map(({ label, value }) => (
                <Grid key={label} item xs={12}>
                  {value && (
                    <>
                      <Typography variant="overline" color="GrayText">
                        {label}
                      </Typography>
                      <Typography variant="body1" display="flex" gap={1}>
                        {value}
                      </Typography>
                    </>
                  )}
                </Grid>
              ))}
              <Grid item xs={12}>
                <Stack gap={1}>
                  {canAddToBuyList && <BookInfoDialogBuyList bookId={id} />}
                  <BookInfoDialogBuyNow bookshopId={bookshopId} isbn={isbn} />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Hidden mdUp>
            <Grid item xs={12}>
              <Divider sx={{ width: "100%" }} />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={8}>
            <Grid container rowSpacing={2}>
              {[
                { label: "Unit", value: `${unitCode} – ${teachingPeriod}` },
                { label: "Choice", value: choiceOfText },
                { label: "Author", value: author },
                { label: "Edition", value: edition },
              ].map(({ label, value }) => (
                <Grid key={label} item xs={6}>
                  {value && (
                    <>
                      <Typography variant="overline" color="GrayText">
                        {label}
                      </Typography>
                      <Typography variant="body1">{value}</Typography>
                    </>
                  )}
                </Grid>
              ))}
              <Grid item xs={12}>
                <BookInfoDialogDetails
                  open={open}
                  loading={details.isLoading}
                  data={details.data}
                />
              </Grid>
              <Divider sx={{ width: "100%", mt: 3 }} />
              <Grid item xs={12}>
                <BookInfoDialogAvailabilities
                  loading={availabilities.isLoading}
                  data={availabilities.data}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
