import { useState } from "react";

import { useBooleanState } from "./state";

/**
 * This hook creates the state and callbacks required to
 * save a HTMLElement anchor for a menu.
 * @param openCallback Callback to be called when the open menu function is executed.
 * @param closeCallback Callback to be called when the close menu function is executed.
 * @returns `[menuAnchor, openMenu, closeMenu]`
 */
export const useMenuAnchor: (
  openCallback?: (event: React.MouseEvent<HTMLElement>) => void,
  closeCallback?: () => void,
) => [HTMLElement | null, (event: React.MouseEvent<HTMLElement>) => void, () => void] = (
  openCallback = () => null,
  closeCallback = () => null,
) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    openCallback(event);
    setMenuAnchor(event.currentTarget);
  };
  const closeMenu = () => {
    closeCallback();
    setMenuAnchor(null);
  };
  return [menuAnchor, openMenu, closeMenu];
};

/**
 * This hook creates the state and callbacks required to
 * save a HTMLElement anchor for a menu along with some data.
 * @param openCallback Callback to be called when the open menu function is executed.
 * @param closeCallback Callback to be called when the close menu function is executed.
 * @returns `[anchor, data, openWithData, closeMenu]`
 */
export const useMenuWithData: <T>(
  defaultData: T,
  openCallback?: (event: React.MouseEvent<HTMLElement>, data: T) => void,
  closeCallback?: () => void,
) => [
  HTMLElement | null,
  T,
  (event: React.MouseEvent<HTMLElement>, data: T) => void,
  () => void,
] = <T>(
  defaultData: T,
  openCallback: (event: React.MouseEvent<HTMLElement>, data: T) => void = () => null,
  closeCallback: () => void = () => null,
) => {
  const [anchor, openMenu, closeMenu] = useMenuAnchor(undefined, closeCallback);
  const [data, setData] = useState<T>(defaultData);

  const openWithData = (event: React.MouseEvent<HTMLElement>, data: T) => {
    openMenu(event);
    openCallback(event, data);
    setData(data);
  };

  return [anchor, data, openWithData, closeMenu];
};

/**
 * This hook creates the state and callbacks required to
 * open and close a dialog along with some data.
 * @param openCallback Callback to be called when the open dialog function is executed.
 * @param closeCallback Callback to be called when the close dialog function is executed.
 * @returns `[isOpen, data, openWithData, close]`
 */
export const useDialogWithData: <T>(
  defaultData: T,
  openCallback?: (data: T) => void,
  closeCallback?: () => void,
) => [boolean, T, (data: T) => void, () => void] = <T>(
  defaultData: T,
  openCallback: (data: T) => void = () => null,
  closeCallback: () => void = () => null,
) => {
  const [isOpen, openDialog, closeDialog] = useBooleanState(false);
  const [data, setData] = useState<T>(defaultData);

  const openWithData = (data: T) => {
    openDialog();
    openCallback(data);
    setData(data);
  };

  const close = () => {
    closeDialog();
    closeCallback();
  };

  return [isOpen, data, openWithData, close];
};
