/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { UserSearchResult } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class SearchApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Search API
   * @name SearchStudentByUnit
   * @summary Search for students who are enrolled in the specified unit
   * @request GET:/api/search/student/unit/{unitCode}
   * @response `200` `(UserSearchResult)[]`
   */
  searchStudentByUnit = (
    unitCode: string,
    query?: {
      year?: string;
      limit?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<UserSearchResult[], any>({
      path: `/api/search/student/unit/${unitCode}`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}
