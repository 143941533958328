import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";

import { useStyles } from "~/utils/styles";
import { BookInfoDialog } from "~/components/book/BookInfoDialog";
import { Title } from "~/components/core/Title";
import { BuyList } from "~/components/booklist/BuyList";
import { BookshopInfoDialog } from "~/components/booklist/BookshopInfoDialog";

export function StudentBuyList() {
  const { below } = useStyles();
  const action = (
    <Button
      endIcon={<HomeIcon />}
      component={Link}
      to="/books"
      sx={{ [below.sm]: { width: "100%" } }}
    >
      Back to booklist
    </Button>
  );

  return (
    <>
      <Title subtitle="Purchase books from official Monash bookshops." action={action}>
        YOUR <b>BUY LIST</b>
      </Title>
      <BuyList />
      <BookInfoDialog canAddToBuyList />
      <BookshopInfoDialog />
    </>
  );
}
