import { createBrowserRouter } from "react-router-dom";

import { RootErrorBoundary } from "~/components/core/RootErrorBoundary/RootErrorBoundary";
import { Guard } from "~/components/auth/Guard";

import { Root } from "./pages/root";

import { StudentBooks } from "./pages/student/books";

import { AdminDashboard } from "./pages/admin/index";
import { AdminManage } from "./pages/admin/manage";
import { AdminUpload } from "./pages/admin/upload";
import { AdminEvents } from "./pages/admin/events";
import { AdminPreview } from "./pages/admin/preview";
import { AdminSearch } from "./pages/admin/search";
import { StudentBuyList } from "./pages/student/buy";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <RootErrorBoundary />,
    children: [
      // Student
      {
        path: "books",
        element: <StudentBooks />,
      },
      {
        path: "buy",
        element: <StudentBuyList />,
      },

      // Staff
      {
        path: "admin",
        element: <Guard type="staff" />,
        children: [
          {
            index: true,
            element: <AdminDashboard />,
          },
          {
            path: "manage",
            children: [
              {
                index: true,
                element: <AdminManage />,
              },
              {
                path: "upload",
                element: <AdminUpload />,
              },
            ],
          },
          {
            path: "preview",
            element: <AdminPreview />,
          },
          {
            path: "search",
            element: <AdminSearch />,
          },
          {
            path: "events",
            element: <AdminEvents />,
          },
        ],
      },
    ],
  },
]);
