import { useEffect, useState } from "react";
import { z } from "zod";

import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useIsResourceLoading } from "~/components/core/AppLoadingBar";
import { PreviewSearchResults } from "~/components/search/PreviewSearchResults";
import { useLocationSearchParams } from "~/utils/route";
import { capitalise } from "@packages/utils";

export const PreviewSearchTypeSchema = z
  .enum(["email", "studentId", "unitCode"])
  .default("unitCode");
export type PreviewSearchType = z.infer<typeof PreviewSearchTypeSchema>;

export const PreviewSearchTypeLabel: Record<PreviewSearchType, string> = {
  email: "email",
  studentId: "student ID",
  unitCode: "unit code",
};

export function usePreviewSearch() {
  const [params, navigate] = useLocationSearchParams<"type" | "value">();
  const type = PreviewSearchTypeSchema.safeParse(params.get("type"));
  return [
    type.success ? type.data : undefined,
    params.get("value") ?? "",
    navigate,
  ] as const;
}

export function PreviewSearchField() {
  const [type, value, navigate] = usePreviewSearch();
  const loading = useIsResourceLoading(PreviewSearchResults.name);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
  }, [inputValue]);

  useEffect(() => {
    if (!inputValue && value) setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (!type) navigate({ type: "unitCode" }, { replace: true });
  }, [type, navigate]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!type) return;
        const sanitised = inputValue.trim();
        if (!sanitised) {
          setError(`Please enter a valid ${PreviewSearchTypeLabel[type]}`);
        } else {
          setError("");
          navigate({ value: inputValue.trim() });
        }
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth size="small" disabled={loading}>
            <InputLabel htmlFor="preview-search-type">Search by</InputLabel>
            <Select
              id="preview-search-type"
              label="Search by"
              value={type ?? "unitCode"}
              onChange={(e) => {
                navigate({ type: e.target.value });
              }}
            >
              {(["unitCode", "studentId", "email"] as const).map((value) => (
                <MenuItem key={value} value={value}>
                  {capitalise(PreviewSearchTypeLabel[value])}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <TextField
            type="text"
            variant="outlined"
            fullWidth
            size="small"
            error={Boolean(error)}
            disabled={loading}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            helperText={error}
            placeholder={`Enter ${PreviewSearchTypeLabel[type ?? "unitCode"]}`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit" disabled={loading}>
                    <ArrowForwardIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
}
