import Stack from "@mui/material/Stack";

import { Title } from "~/components/core/Title";
import { PreviewSearchField } from "~/components/search/PreviewSearchField";
import { PreviewSearchResults } from "~/components/search/PreviewSearchResults";
import { BookInfoDialog } from "~/components/book/BookInfoDialog";

export function AdminPreview() {
  return (
    <>
      <Title>
        BOOKLIST <b>PREVIEW</b>
      </Title>
      <Stack spacing={3}>
        <PreviewSearchField />
        <PreviewSearchResults />
      </Stack>
      <BookInfoDialog />
    </>
  );
}
