import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import { FixedSizeList } from "react-window";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import TextField from "@mui/material/TextField";

import { pluralise } from "@packages/utils";

import { useGetBooklistUnitCodeFilters } from "~/data/booklists";
import { useBooklistListPage } from "~/components/booklist/BooklistList";
import { useSelectedBooklist } from "~/components/booklist/BooklistSelect";
import { useSelectedBooklistUnitCodeFilter } from "~/components/booklist/BooklistUnitCodeFilter";

const BooklistUnitCodeFilterDialogOpen = atom(false);

export function useBooklistUnitCodeFilterDialog() {
  return useAtom(BooklistUnitCodeFilterDialogOpen);
}

export function BooklistUnitCodeFilterDialog() {
  const [open, setOpen] = useBooklistUnitCodeFilterDialog();
  const [filter, setFilter] = useSelectedBooklistUnitCodeFilter();
  const [search, setSearch] = useState("");

  const booklist = useSelectedBooklist();
  const { data = [] } = useGetBooklistUnitCodeFilters(booklist?.id, search);
  const [, setPage] = useBooklistListPage();

  // Reset filter when booklist changes
  useEffect(() => {
    setFilter("");
  }, [booklist, setFilter]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Filter by unit {filter}</DialogTitle>
      <DialogContent
        sx={{
          flexShrink: 0,
          overflow: "visible",
          minWidth: 320,
          pb: 0,
        }}
      >
        <TextField
          fullWidth
          label="Search unit code"
          variant="outlined"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </DialogContent>
      <DialogContent sx={{ pt: 1 }}>
        <ListItem disablePadding>
          <ListItemButton
            disabled={!filter}
            onClick={() => setFilter("")}
            sx={{ borderRadius: 1 }}
          >
            <ListItemText secondary="Clear filter" />
          </ListItemButton>
        </ListItem>
        <FixedSizeList width={280} height={480} itemCount={data.length} itemSize={72}>
          {({ index, style }) => {
            const { key, count } = data[index];
            return (
              <ListItem key={key} disablePadding style={style}>
                <ListItemButton
                  sx={{ borderRadius: 1 }}
                  onClick={() => {
                    setFilter(filter === key ? "" : key);
                    setPage(0);
                  }}
                  selected={key === filter}
                >
                  <ListItemText primary={key} secondary={pluralise(count, "book")} />
                </ListItemButton>
              </ListItem>
            );
          }}
        </FixedSizeList>
      </DialogContent>
    </Dialog>
  );
}
