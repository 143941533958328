import { Link } from "react-router-dom";

import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

import { Title } from "~/components/core/Title";

const links = [
  {
    path: "manage",
    title: "Booklist management",
    description: "View, upload or delete booklists",
  },
  {
    path: "preview",
    title: "Booklist preview",
    description: "View the booklist for a student or unit",
  },
  {
    path: "search",
    title: "Student search",
    description: "Search for students who are enrolled in a specific unit",
  },
  {
    path: "events",
    title: "Audit log",
    description: "View the audit log for recent uploads and other actions",
  },
];

export function AdminDashboard() {
  return (
    <>
      <Title subtitle="Administration dashboard">
        <b>MONASH</b> STUDENT BOOKLIST
      </Title>
      <Paper square sx={{ mt: 2, mb: 2 }}>
        <List disablePadding>
          {links.map(({ path, title, description }) => (
            <ListItemButton key={path} component={Link} to={`/admin/${path}`}>
              <ListItemText primary={title} secondary={description} />
            </ListItemButton>
          ))}
        </List>
      </Paper>
    </>
  );
}
