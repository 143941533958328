import { useQuery } from "@tanstack/react-query";

import { BooksApi, useApi } from "~/api";

export function useGetMyBooklist() {
  const api = useApi(BooksApi);
  return useQuery({
    queryKey: ["my", "books"],
    queryFn: () => api.getBooks({}),
  });
}
export function useGetBookDetails(bookId: string = "") {
  const api = useApi(BooksApi);
  return useQuery({
    queryKey: ["books", bookId, "details"],
    queryFn: () => api.getBookDetails(bookId),
    enabled: Boolean(bookId),
    retry: false,
  });
}

export function useGetBookAvailabilities(bookId: string = "") {
  const api = useApi(BooksApi);
  return useQuery({
    queryKey: ["books", bookId, "availabilities"],
    queryFn: () => api.getBookAvailabilities(bookId),
    enabled: Boolean(bookId),
    retry: false,
  });
}
