import { useTheme, lighten, darken } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { FontFamily, MonashPalette } from "~/config/theme";

export function useStyles() {
  const theme = useTheme();
  return {
    above: {
      xs: theme.breakpoints.up("xs"),
      sm: theme.breakpoints.up("sm"),
      md: theme.breakpoints.up("md"),
      lg: theme.breakpoints.up("lg"),
    },
    below: {
      sm: theme.breakpoints.down("sm"),
      md: theme.breakpoints.down("md"),
      lg: theme.breakpoints.down("lg"),
      xl: theme.breakpoints.down("xl"),
    },
    palette: theme.palette,
    typography: theme.typography,
    shape: theme.shape,
    shadows: theme.shadows,
    transitions: theme.transitions,
    lighten,
    darken,
    FontFamily,
    Monash: MonashPalette,
    theme,
  };
}

export { useMediaQuery };
