import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useStyles } from "~/utils/styles";

export interface TitleProps {
  children?: React.ReactNode;
  subtitle?: React.ReactNode;
  action?: React.ReactNode;
}

export function Title(props: TitleProps) {
  const { children: title, subtitle, action } = props;
  const { below, FontFamily } = useStyles();
  return (
    <Box
      component="span"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={2}
      mb={2}
      sx={{
        [below.sm]: { flexWrap: "wrap" },
      }}
    >
      <Stack gap={1}>
        <Typography
          variant="h4"
          variantMapping={{ h4: "h2" }}
          fontFamily={FontFamily.condensed}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="body1" color="GrayText">
            {subtitle}
          </Typography>
        )}
      </Stack>
      {action}
    </Box>
  );
}
