import { useState, useCallback, useEffect } from "react";

export type ValueUpdater<T> = ((prev?: T) => T) | T;

/**
 * Create a `useState` hook that returns the state,
 * a function to set the state as true, and a function
 * to set the state as false.
 * @param defaultState initial value for the state
 */
export const useBooleanState: (
  defaultState?: boolean,
) => [boolean, () => void, () => void] = (defaultState: boolean = false) => {
  const [state, setState] = useState<boolean>(defaultState);
  const setTrue = useCallback(() => setState(true), [setState]);
  const setFalse = useCallback(() => setState(false), [setState]);
  return [state, setTrue, setFalse];
};

/**
 * Create a `useState` hook that returns the state,
 * and a function that toggles the state.
 * @param defaultState initial value for the state
 */
export const useToggleBooleanState: (defaultState: boolean) => [boolean, () => void] = (
  defaultState: boolean,
) => {
  const [state, setState] = useState<boolean>(defaultState);
  const toggle = useCallback(() => setState(!state), [setState, state]);
  return [state, toggle];
};

/**
 * Create a `useState` hook that initialises a map that
 * can be used to store arbitrary values. Returns the
 * map, a setter function that sets only the specified
 * field, and the original setState function that can be
 * used to update the entire map value.
 * @param defaultState initial value for the state
 */
export const useMapState: <T = Record<string, unknown>>(defaultState?: {
  [key: string]: T;
}) => [
  { [key: string]: T },
  (key: string, update: ValueUpdater<T>) => void,
  (value: { [key: string]: T }) => void,
] = <T = Record<string, unknown>>(
  defaultState: {
    [key: string]: T;
  } = {},
) => {
  const [values, setValues] = useState<{ [key: string]: T }>(defaultState);

  /**
   * Sets the value for the specified key in the map.
   * @param key the key of the value to set
   * @param value a value or update function
   */
  const setValue = (key: string, value: ValueUpdater<T>) => {
    if (typeof value === "function") {
      setValues((prev) => ({
        ...prev,
        [key]: (value as (prev: T) => T)(prev[key]),
      }));
    } else {
      setValues((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  return [values, setValue, setValues];
};

/**
 * This hook returns an object with the setter and getter for
 * a value field and error field.
 *
 * @param defaultValue The default value for the field.
 */
export const useTextField = <T = string>(defaultValue: T) => {
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState("");

  // If the value is updated, reset the error
  useEffect(() => {
    setError("");
  }, [value]);

  return { value, setValue, error, setError, defaultValue };
};

/**
 * This hook returns an object with the setter and getter for
 * a value field and error field. The `reset` field can be used
 * to trigger a reset every time its value becomes `true`.
 *
 * @param defaultValue The default value for the field. Value is used to reset the field.
 * @param reset The reset flag.
 */
export const useEffectTextField = <T = string>(defaultValue: T, reset?: boolean) => {
  const { value, setValue, error, setError } = useTextField<T>(defaultValue);
  const [prevReset, setReset] = useState(reset);

  useEffect(() => {
    // Keep reset flag in sync
    if (prevReset && !reset) {
      setReset(reset);
    }

    // If the reset flag is true, reset values.
    if (!prevReset && reset) {
      setReset(reset);
      setValue(defaultValue);
      setError("");
    }
  }, [setValue, setError, reset, prevReset, setReset, defaultValue]);

  // If the value is updated, reset the error
  useEffect(() => {
    setError("");
  }, [value, setError]);

  return { value, setValue, error, setError, defaultValue };
};
